import { NgModule } from "@angular/core";
import { routing } from "./ecosystem.routing";


@NgModule({
    declarations: [
    ],
    exports: [
    ],
    imports: [
        routing,
    ]
})
export class EcoSystemModule {
}