import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ExcelModule, GridModule } from "@progress/kendo-angular-grid";
import { IgniteKendoGridPdfModule } from "../../shared/ignite/ignite-kendo-grid-pdf/ignite-kendo-grid-pdf.module";
import { SmartadminModule } from "../../shared/smartadmin.module";
import { ActiveSessionsListComponent } from "./active-sessions-list.component";
import { ActiveSessionsService } from "./active-sessions.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SmartadminModule,
        ExcelModule,
        GridModule,
        IgniteKendoGridPdfModule
    ],
    declarations: [
        ActiveSessionsListComponent,
    ],
    providers: [
        ActiveSessionsService
    ]
})
export class ActiveSessionsModule { }