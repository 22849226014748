
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../+auth/auth-guard.service";
import { ScopeGuardService } from "../+auth/scope-guard.service";


export const routes: Routes = [
   {
     path: 'asset-management-reports',
     loadChildren: () => import('app/reports/asset-management-reports/asset-management-reports.module').then(m => m.AssetManagementReportsModule),
     canActivate: [AuthGuard],
     data: { pageTitle: 'Asset Management Reports' }
   },
  {
    path: 'safety-reports',
    loadChildren: () => import('app/reports/safety-reports/safety-reports.module').then(m => m.SafetyReportsModule),
    canActivate: [AuthGuard],
    data: { pageTitle: 'Safety Reports' }
  },
  {
    path: 'scheduled',
    loadChildren: () => import('app/reports/scheduled/scheduled-reports.module').then(m => m.ScheduledReportsModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Scheduled Reports', expectedScopes: ["browse:scheduled-report"] }
  },
  {
    path: 'administrative-reports',
    loadChildren: () => import('app/reports/administrative-reports/administrative-reports.module').then(m => m.AdministrativeReportsModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Administrative Reports', expectedScopes: ["browse:user"] }
  }
];

export const routing = RouterModule.forChild(routes);
