import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "app/shared/ignite/configuration.service";
import { Observable, of, throwError as observableThrowError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ISearchCriteria } from "../../shared/ignite/search-criteria";
import { IUser } from "./user";

@Injectable()
export class UsersService {
    private currentUserUrl: string;
    private baseUrl: string;

    constructor(private configurationService: ConfigurationService,
        private httpClient: HttpClient) {        
        this.currentUserUrl = `${this.configurationService.apiBaseUrl}/user/current`;
        this.baseUrl = `${this.configurationService.apiBaseUrl}`;
    }    

    public get newRecordId(): string {
        return "new";
    }

    protected get httpOptions(): object {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
    }

    protected handleError<T>(result?: T) {
        return (error: any): Observable<T> => {
            let errorMsg = "The application has encountered an unknown error. If the error persists, please contact the support team.";
            
            if (error && error.status === 403){
                return observableThrowError("You do not have permission to perform this action, please contact the support team");
            }   
            
            if (!error.error){
                return observableThrowError(errorMsg);
            }   
           
            return observableThrowError(error.error.type && error.error.type === "error" ? errorMsg : 
            error.error );
        };
    }

    public delete(id: string, tenantId: string): Observable<IUser> {
        let url = `${this.baseUrl}/tenant/${tenantId}/user/`;

        return this.httpClient.delete(url + id, this.httpOptions)
            .pipe(catchError(this.handleError(null)));
    }

    public getAll(tenantId: string): Observable<IUser[]> {
        let baseUrl = `${this.baseUrl}/tenant/${tenantId}/user/`;

        return this.httpClient.get<IUser[]>(baseUrl)
            .pipe(catchError(this.handleError(null)));
    }

    public getAuthUsers(tenantIds: string[]): Observable<IUser[]> {
        let baseUrl = `${this.baseUrl}/tenant/${tenantIds[0]}/user/`;

        return this.httpClient.post<IUser[]>(baseUrl + 'auth', tenantIds, this.httpOptions)
            .pipe(catchError(this.handleError([])));
    }

    public getRolePermissions(roleId: string): Observable<string[]> {
        const baseUrl = `${this.baseUrl}/role/${roleId}/permissions`;
        return this.httpClient.get<string[]>(baseUrl)
            .pipe(catchError(this.handleError(null)));
    }


    public getAllCustomerUsers(tenantId: string, customerId: string): Observable<IUser[]> {
        let baseUrl = `${this.baseUrl}/tenant/${tenantId}/user/`;

        if (customerId) {
            baseUrl = `${this.baseUrl}/tenant/${customerId}/user/`;
        }
        else {
            baseUrl = baseUrl + 'customersUsers';
        }

        return this.httpClient.get<IUser[]>(baseUrl)
            .pipe(catchError(this.handleError([])));
    }

    public getCurrent(): Observable<IUser> {
        return this.httpClient.get<IUser>(this.currentUserUrl).pipe(
            tap(result => sessionStorage.setItem("currentUserDetails", JSON.stringify(result))))
            .pipe(catchError(this.handleError(null)));
    }

    public getById(id: string, tenantId: string): Observable<IUser> {
        let url = `${this.baseUrl}/tenant/${tenantId}/user/`;

        if (id === this.newRecordId) {
            return of(this.createEmpty());
        }

        return this.httpClient.get<IUser>(url + id)
            .pipe(catchError(this.handleError(null)));
    }

    public isUserAdmin(authenticationProviderUserId: string, tenantId: string): Observable<boolean> {
        let url = `${this.baseUrl}/tenant/${tenantId}/user/`;

        return this.httpClient.get<boolean>(url + authenticationProviderUserId + '/isUserAdmin')
            .pipe(catchError(this.handleError(null)));
    }

    public getByAuthenticationProviderUserId(id: string, tenantId: string, hasImpersonatePermission: boolean = false): Observable<IUser> {
        let url = `${this.baseUrl}/tenant/${tenantId}/user/`;

        if (id === this.newRecordId) {
            return of(this.createEmpty());
        }

        return this.httpClient.get<IUser>(url + 'authenticationProviderUserId/' + id + (hasImpersonatePermission === true ? '?hasImpersonatePermission=true' : ''))
            .pipe(catchError(this.handleError(null)));
    }

    public search(searchCriteria: ISearchCriteria, tenantId: string): Observable<IUser[]> {
        let url = `${this.baseUrl}/tenant/${tenantId}/user/`;

        return <Observable<IUser[]>>this.httpClient.post<IUser[]>(url + "search", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError([])));
    }

    public searchRecursive(searchCriteria: ISearchCriteria, tenantId: string): Observable<IUser[]> {
        let url = `${this.baseUrl}/tenant/${tenantId}/user/`;

        return <Observable<IUser[]>>this.httpClient.post<IUser[]>(url + "search/recursive", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError([])));
    }

    public block(id: string, isBlocked: boolean, tenantId: string): Observable<IUser> {
        let url = `${this.baseUrl}/tenant/${tenantId}/user/`;

        return this.httpClient.put<IUser>(url + id + "/block/" + isBlocked, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public impersonate(authenticationProviderUserId: string, newTenantId: string): Observable<IUser> {
        let baseUrl = `${this.baseUrl}/user/`;
        return this.httpClient.put<IUser>(baseUrl + authenticationProviderUserId + "/impersonate/" + newTenantId, this.httpOptions)
            .pipe(catchError(this.handleError(null)));
    }

    public switchIdentity(authenticationProviderUserId: string, newTenantId: string): Observable<IUser> {
        let baseUrl = `${this.baseUrl}/user/`;
        return this.httpClient.put<IUser>(baseUrl + authenticationProviderUserId + "/switchIdentity/" + newTenantId, this.httpOptions)
            .pipe(catchError(this.handleError(null)));
    }

    public save(user: IUser, tenantId: string, propagatePermissions: boolean = false): Observable<IUser> {
        let url = `${this.baseUrl}/tenant/${tenantId}/user/`;

        if (user.id == this.newRecordId) {
            return this.httpClient.post<IUser>(url, user, this.httpOptions)
                .pipe(catchError(this.handleError(this.createEmpty())));
        }

        return this.httpClient.put<IUser>(url + user.id + `?propagatePermissions=${propagatePermissions}`, user, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public clearUserChache(authenticationProviderUserId: string): Observable<boolean> {
        let baseUrl = `${this.baseUrl}/memoryCache/clearUser/authenticationProviderUserId/`;
        return this.httpClient.put<boolean>(baseUrl + authenticationProviderUserId , this.httpOptions)
            .pipe(catchError(this.handleError(null)));
    }
    
    public shareWithBranches(userId: string, branchIds: string[],  tenantId?: string): Observable<IUser[]> {
        let url: string = this.baseUrl;
        if (tenantId) {
            url = `${this.baseUrl}/tenant/${tenantId}/user/${userId}/shareWithBranches`;
        }

        return this.httpClient.put<IUser[]>(url, branchIds, this.httpOptions)
            .pipe(catchError(this.handleError([])));
    }

    public getSharedParent(id: string, tenantId: string): Observable<IUser> {
        let url = `${this.baseUrl}/tenant/${tenantId}/user/${id}/getSharedParent`;

        return this.httpClient.get<IUser>(url)
            .pipe(catchError(this.handleError(null)));
    }

    public getSharedChildren(id: string, tenantId: string): Observable<IUser[]> {
        let url = `${this.baseUrl}/tenant/${tenantId}/user/${id}/getSharedChildren`;

        return this.httpClient.get<IUser[]>(url)
            .pipe(catchError(this.handleError(null)));
    }

    private createEmpty(): IUser {
        return {
            id: this.newRecordId,
            email: null,
            firstName: null,
            lastName: null,
            employeeId: null,
            description: null,
            signatureImage: { contentType: null, effectiveDate: null, internalName: null, name: null },
            isAdmin: false,
            isBlocked: false,
            name: null,
            tenantId: null,
            userId: null,
            permissions: [],
            preferences: [],
            restrictions:[],
            hasLicense: false,
            sharedWithIds: []
        };
    }
}
