import { Injectable } from '@angular/core';
import { Service } from '../service';
import { ApplicationContext } from '../application-context';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IListState, ListType } from './list-state.model';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ListStateService extends Service {
    private baseUrl: string;

    constructor(applicationContext: ApplicationContext,
        private httpClient: HttpClient) {
        super(applicationContext);

        this.baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${this.tenantId}/listState/`;
    }

    public getAll(listType: ListType): Observable<IListState[]> {
        return this.httpClient.get<IListState[]>(this.baseUrl + listType, this.httpOptions)
            .pipe(catchError(this.handleError([])));
    }

    public getById(id: string): Observable<IListState> {
        return this.httpClient.get<IListState>(this.baseUrl + `getById/${id}` , this.httpOptions)
            .pipe(catchError(this.handleError(null)));
    }

    public delete(id: string): Observable<IListState> {
        return this.httpClient.delete(this.baseUrl + id, this.httpOptions)
            .pipe(catchError(this.handleError(null)));
    }

    public save(listState: IListState): Observable<IListState> {
        
        if (listState.id === null) {
           return this.httpClient.post<IListState>(this.baseUrl, listState, this.httpOptions)
                .pipe(catchError(this.handleError(this.createEmpty())));
        }

        return this.httpClient.put<IListState>(this.baseUrl + listState.id, listState, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    private createEmpty(): IListState {
        return {
            id: this.newRecordId,
            tenantId: null,
            listType: null,
            name: null,
            searchCriteriaItems: null,
            location: {id: null, name: null},
            manufacturer:  {id: null, name: null},
            owner:  {id: null, name: null},            
            contextId: null,
            columnsConfiguration: [],
            listGridState: {},
            isPrivated: true
        };
    }
}