
import { RouterModule, Routes } from "@angular/router";
import { ScopeGuardService } from "../+auth/scope-guard.service";


export const routes: Routes = [

    {
        path: 'assets',
        loadChildren: () => import('app/asset-management/assets/assets.module').then(m => m.AssetsModule),
        canActivate: [ScopeGuardService],
        data: { pageTitle: 'Assets', expectedScopes: ["browse:asset"] }
    },
    {
        path: 'asset-templates',
        loadChildren: () => import('app/asset-management/asset-templates/asset-templates.module').then(m => m.AssetTemplatesModule),
        canActivate: [ScopeGuardService],
        data: { pageTitle: 'Asset Templates', expectedScopes: ["browse:asset-template"] }
    },
    {
        path: 'categories',
        loadChildren: () => import('app/asset-management/categories/categories.module').then(m => m.CategoriesModule),
        canActivate: [ScopeGuardService],
        data: { pageTitle: 'Categories', expectedScopes: ["browse:category"] }
    },
    {
        path: 'contacts',
        loadChildren: () => import('app/asset-management/reorder/reorder-contacts/reorder-contacts.module').then(m => m.ReorderContactsModule),
        canActivate: [ScopeGuardService],
        data: { pageTitle: 'Contacts', expectedScopes: ["browse:contact"] }
    },
    {
        path: 'forms',
        loadChildren: () => import('app/asset-management/forms/forms.module').then(m => m.AppFormsModule),
        canActivate: [ScopeGuardService],
        data: { pageTitle: 'Forms', expectedScopes: ["browse:form"] }
    },
    {
        path: 'reorder-tracking',
        loadChildren: () => import('app/asset-management/reorder/reorder-tracking/reorder-tracking.module').then(m => m.ReorderTrackingModule),
        canActivate: [ScopeGuardService],
        data: { pageTitle: 'Reorder Tracking', expectedScopes: ["browse:reorder"] }
    },
    {
        path: 'tags',
        loadChildren: () => import('app/asset-management/tags/tags.module').then(m => m.TagsModule),
        canActivate: [ScopeGuardService],
        data: { pageTitle: 'Tags', expectedScopes: ["browse:tag"] }
    },
    {
        path: 'custom-statuses',
        loadChildren: () => import('app/asset-management/custom-statuses/custom-statuses.module').then(m => m.CustomStatusesModule),
        canActivate: [ScopeGuardService],
        data: { pageTitle: 'Custom Statuses', expectedScopes: ["browse:custom-status"] }
    },
    {
        path: 'check-in-out',
        loadChildren: () => import('app/asset-management/check-in-out/check-in-out.module').then(m => m.CheckInOutModule),
        canActivate: [ScopeGuardService],
        data: { pageTitle: 'Asset Check-in Checkout', expectedScopes: ["check-in:asset", "check-out:asset"] }
    }
];

export const routing = RouterModule.forChild(routes);
