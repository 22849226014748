import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PDFModule } from "@progress/kendo-angular-grid";
import { ThemesService } from "../../../settings/themes/themes.service";
import { AttachmentsService } from "../../attachments/attachments.service";
import { I18nModule } from "../../i18n/i18n.module";
import { IgniteKendoGridPdfComponent } from "./ignite-kendo-grid-pdf.component";

@NgModule({
    imports: [
        CommonModule,
        PDFModule,
        I18nModule,
    ],
    declarations: [
        IgniteKendoGridPdfComponent,
    ],
    exports: [
        IgniteKendoGridPdfComponent
    ],
    providers: [
        ThemesService,
        AttachmentsService
    ]
})
export class IgniteKendoGridPdfModule { }