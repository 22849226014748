import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'app/shared/ignite/base.component';
import { ApplicationContext } from 'app/shared/ignite/application-context';
import { FileRestrictions, SuccessEvent, UploadProgressEvent } from '@progress/kendo-angular-upload';
import { TenantsService } from 'app/settings/tenants/tenants.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { IDropdownList } from 'app/shared/ignite/dropdown-list/dropdown-list.model';
import { ITenant } from 'app/settings/tenants/tenant';
import { SynchronizationService } from './synchronization.service';

@Component({
  templateUrl: './synchronization.component.html'
})
export class SynchronizationComponent extends BaseComponent implements OnInit {

  public uploadSaveUrl: string;      
  public restrictions: FileRestrictions = { allowedExtensions: ['zip'] };  

  public selectedTenant: string;

  constructor(applicationContext: ApplicationContext,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: FormBuilder,
    private synchronizationService: SynchronizationService,
    public tenantsService: TenantsService) {
    super(applicationContext, activatedRoute, formBuilder);

    this.breadcrumbs = [
      this.applicationContext.i18nService.getTranslation("Synchronization")
    ];

    this.uploadSaveUrl = `${this.applicationContext.configurationService.apiBaseUrl}/synchronization/upload`;
  }

  ngOnInit() { }
  
  public convertTenantToDropdown(assets: ITenant[]): IDropdownList[] {
    return assets.map(n => <IDropdownList>{ id: n.id, name: n.corporateContact.name });
  }
    
  public syncEventHandler(e: UploadProgressEvent) {
    if(e.percentComplete == 100)
    {
      this.alertService.success(this.i18nService.getTranslation('File(s) uploaded successfully. The Synchronization is being processed in background. You will receive an email when the synchronization is finalized.'));
    }
  }

  public syncInitialize(){
    this.showLoading();
    this.synchronizationService.syncInitiliaze(this.selectedTenant).subscribe(n => {
       this.alertService.success(this.applicationContext.i18nService.getTranslation('Tenant sucessfully Initialized.'));
       this.hideLoading();
    },
    error => {
      this.alertService.error(error);
      this.hideLoading();
    })
  }


}