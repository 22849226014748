
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { ConfigurationService } from "../../../shared/ignite/configuration.service";
import { IUserPreferences } from "./user-preferences.model";
import { DueDateCalculationType } from '../tenant-preferences/due-date-calculation-type.enum';


@Injectable()
export class UserPreferencesService {
    private baseUrl: string;

    constructor(configurationService: ConfigurationService,
        private httpClient: HttpClient) {

        this.baseUrl = `${configurationService.apiBaseUrl}/userPreferences/`;
    }

    public get newRecordId(): string {
        return "new";
    }

    protected get httpOptions(): object {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
    }

    protected handleError<T>(result?: T) {
        return (error: any): Observable<T> => {
            return observableThrowError(error.error ? error.error : error.statusText);
        };
    }

    public GetUserPreferences(): Observable<IUserPreferences> {
        return this.httpClient.get<IUserPreferences>(this.baseUrl)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public save(preferences: IUserPreferences): Observable<IUserPreferences> {
        if (preferences.id === null) {
            return this.httpClient.post<IUserPreferences>(this.baseUrl, preferences, this.httpOptions)
                .pipe(catchError(this.handleError(this.createEmpty())));
        }

        return this.httpClient.put<IUserPreferences>(this.baseUrl + preferences.id, preferences, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    private createEmpty(): IUserPreferences {
        return {
            id: this.newRecordId,
            language: 'en-US',
            measurementPreferences: [],
            dueDateCalculationType: DueDateCalculationType.ByFrequency,
            autoCopyLastOrderNumberAndPONumber: true,
            rememberLastOwnerAndLocation: true,
            dateFormatPreference: null,
            previewCertAsDefault: false
        };
    }
}