import { Injectable } from '@angular/core';


import {  Observable ,  BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadTimeService {

    private _executionTimes: Array<number> = [1, 2, 3];
    public executionTimes: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(this._executionTimes);

    constructor() {
    }

    Add(executionTime: number): void {
        if (this._executionTimes.length == 10) {
            this._executionTimes.shift();
        }
        this._executionTimes.push(executionTime / 1000);

        this.executionTimes.next(this._executionTimes);
    }

    getExecutionTimes(): Observable<number[]> {
        return this.executionTimes.asObservable();
    }
}
