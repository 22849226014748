import { Component, Input, OnInit } from '@angular/core';
import { ApplicationContext } from '../application-context';

@Component({
    selector: 'ignite-kendo-grid-pdf',
    templateUrl: './ignite-kendo-grid-pdf.component.html',
    styleUrls: ['./ignite-kendo-grid-pdf.css']
})

export class IgniteKendoGridPdfComponent implements OnInit {
    @Input() landscape: boolean;
    @Input() fileName: string;
    @Input() title: string;
    @Input() scale: number = 0.5;
    @Input() allPages = true;
    public reportHeaderLogo: any;
    public reportFooterLogo: any;
    public currentDate: Date = new Date;
    public dateTimeFormat: string;

    constructor(private applicationContext: ApplicationContext) {
        this.reportFooterLogo = this.applicationContext.currentReportFooterLogo;
        this.reportHeaderLogo = this.applicationContext.currentReportHeaderLogo;
    }

    ngOnInit() { }
}