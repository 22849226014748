import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ScopeGuardService } from 'app/+auth/scope-guard.service';
import { DownloadModule } from 'app/download/download.module';
import { IgniteModule } from 'app/shared/ignite/ignite.module';
import { AuthGuard } from '../+auth/auth-guard.service';
import { Auth0Service } from '../+auth/auth0.service';
import { CallbackComponent } from '../callback/callback.component';
import { UserPreferencesService } from '../settings/preferences/user-preferences/user-preferences.service';
import { UsersService } from '../settings/users/users.service';
import { ApplicationContext } from '../shared/ignite/application-context';
import { LayoutService } from '../shared/layout/layout.service';
import { JsonApiService } from './api/json-api.service';
import { throwIfAlreadyLoaded } from './guards/module-import-guard';
import { I18nService } from 'app/shared/i18n';
import { MessageService } from '@progress/kendo-angular-l10n';
import { ConfirmModalModule } from 'app/shared/modal/confirm-modal.module';
import { GridConfigurationsService } from 'app/settings/grid-configurations/grid-configurations.service';

@NgModule({
  imports: [
    CommonModule,
    IgniteModule,
    ConfirmModalModule
  ],
  exports: [
    HttpClientModule,
    DownloadModule
  ],
  declarations: [
    CallbackComponent
  ],
  providers: [
    JsonApiService,
    LayoutService,
    // //VoiceControlService,
    //VoiceRecognitionService,
    //SoundService,

    AuthGuard,
    ScopeGuardService,
    Auth0Service,
    ApplicationContext,
    GridConfigurationsService,
    UsersService,
    UserPreferencesService,
    I18nService,
    { provide: MessageService, useClass: I18nService }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}