import { NgModule } from "@angular/core";
import { routing } from "./export.routing";


@NgModule({
    imports: [      
        routing  
    ],
    declarations: [
    ],
    providers: [
    ]
})
export class ExportModule { }