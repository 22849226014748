import { Component, Input, OnInit } from '@angular/core';

@Component({

  selector: 'sa-big-breadcrumbs',
  template: `
   <div *ngIf="items"><h1 class="page-title txt-color-blueDark">
   <i *ngIf="icon" class="fa-fw fa fa-{{icon}}"></i>
   <img *ngIf="img" class="icon icon-lg icon-{{img}}">
   {{items[0]}}
   <span *ngFor="let item of items.slice(1)">> {{item}}</span>
</h1></div>
  `,
})
export class BigBreadcrumbsComponent implements OnInit {

  @Input() public icon: string;
  @Input() public img: string;
  @Input() public items: Array<string>;


  constructor() { }

  ngOnInit() {
  }

}
