import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Service } from "../../shared/ignite/service";
import { AlertService } from "../ignite/alert.service";
import { ApplicationContext } from "../ignite/application-context";
import { IAttachment } from "./attachment";
import { DomSanitizer } from "@angular/platform-browser";
import { saveAs } from "@progress/kendo-file-saver";



@Injectable()
export class AttachmentsService extends Service {
    private baseUrl: string;

    constructor(applicationContext: ApplicationContext,
        private httpClient: HttpClient,
        private alertService: AlertService,
        private domSanitizer: DomSanitizer) {
        super(applicationContext);

        this.baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${this.tenantId}/attachment/`;
    }

    public getAttachmentByFileName(internalName: string, contentType: string, name: string, tenantId?: string): void {
        let baseUrl = this.baseUrl;
        if (!tenantId) {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/attachment/`;
        }
        else {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/attachment/`;
        }

        const downloadRequest = new HttpRequest('GET', baseUrl + internalName, {
            responseType: 'json'
        });

        this.downloadFile(downloadRequest, contentType, name);
    }

    public getAttachmentByFileNameNew(internalName: string, contentType: string, name: string, tenantId?: string): void {
        let baseUrl = this.baseUrl;
        if (!tenantId) {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/attachment/`;
        }
        else {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/attachment/`;
        }

        let headers = new HttpHeaders();
        headers = headers.set('Accept', contentType);

        this.applicationContext.loadingService.show('main');
        this.httpClient.get(baseUrl + 'new/' + internalName, { headers: headers, responseType: 'blob'} ).subscribe(
         res => {
             if (res && res.size > 0){              
                saveAs(res, name);
             }
             else{
                this.alertService.warning("File not found.");
             }         
             this.applicationContext.loadingService.hide('main');             
          },
          error => {
            this.applicationContext.loadingService.hide('main');
            this.alertService.error(error);
          });
    }

    public delete(internalName: string, tenantId?: string): Observable<Response> {
        let baseUrl = this.baseUrl;
        if (!tenantId) {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/attachment/`;
        }
        else if (tenantId) {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/attachment/`;
        }

        return this.httpClient.delete(baseUrl + '?filename=' + internalName, this.httpOptions)
            .pipe(catchError(this.handleError(null)));
    }

    public deleteMany(internalNames: string[], tenantId?: string): Observable<Response> {
        
        var url = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/attachment/deleteMany`;

        return this.httpClient.post<{fileNames: string[]}>(url, internalNames, this.httpOptions)
            .pipe(catchError(this.handleError(null)));
    }

    private downloadFile(downloadFileHttpRequest: HttpRequest<any>, contentType: string, origFilename: string): void {
        this.applicationContext.loadingService.show('main');
        this.httpClient.request(downloadFileHttpRequest)
            .subscribe(
                (event: HttpEvent<any>) => {
                    if (event && event.type == HttpEventType.Response) {
                        if (event.body){
                            let byteCharacters = atob(event.body);

                            let byteNumbers = new Array(byteCharacters.length);
    
                            for (var i = 0; i < byteCharacters.length; i++)
                                byteNumbers[i] = byteCharacters.charCodeAt(i);
    
                            let byteArray = new Uint8Array(byteNumbers);
    
                            const blob = new Blob([byteArray], { type: contentType });
    
                            saveAs(blob, origFilename);
                        }
                        else
                        {
                            this.alertService.warning("File not found.");  
                        }
                       
                        this.applicationContext.loadingService.hide('main');
                    }
                },
                (err) => {
                    this.applicationContext.loadingService.hide('main');
                    this.alertService.error(err.message);
                    this.handleError(err);
                }
            );
    }   

    public getContentByFileName(internalName: string, tenantId?: string): Observable<string> {
        let baseUrl = this.baseUrl;
        if (!tenantId) {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/attachment/`;
        }
        else {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/attachment/`;
        }

        return this.httpClient.get<string>(baseUrl + internalName, this.httpOptions)
            .pipe(catchError(this.handleError(null)));
    }   

    public uploadFiles(files: { internalName: string, base64Image: string }[], isGlobal?: boolean, tenantId?: string): Observable<Response> {
        let baseUrl = this.baseUrl;
        if (isGlobal === true) {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/attachment/`;
        } else if (tenantId) {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/attachment/`;
        }

        return this.httpClient.post<{ internalName: string, base64Image: string }[]>(baseUrl + 'uploadFiles', files, this.httpOptions)
            .pipe(catchError(this.handleError(null)));
    }    

    public uploadFilesByFormData(files: { internalName: string, file: File }[], tenantId?: string): Observable<Response> {
        let baseUrl = this.baseUrl;
        if (!tenantId) {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/attachment/`;
        }
        else {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/attachment/`;
        }

        let formData:FormData = new FormData();

        files.forEach(x => {
            formData.append(x.internalName, x.file, x.file.name);
        });        

        return this.httpClient.post(baseUrl + 'uploadFilesByFormData', formData)
            .pipe(catchError(this.handleError(null)));
    }

    public syncFilesByFormData(files: { internalName: string, file: File }[], tenantId?: string): Observable<Response> {
        let baseUrl = this.baseUrl;
        if (!tenantId) {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/attachment/`;
        }
        else {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/attachment/`;
        }

        let formData:FormData = new FormData();

        files.forEach(x => {
            formData.append(x.internalName, x.file, x.file.name);
        });        

        return this.httpClient.post(baseUrl + 'syncFilesByFormData', formData)
            .pipe(catchError(this.handleError(null)));
    }

    private createEmpty(): IAttachment {
        return {
            attachmentLabelId: null,
            name: "",
            contentType: null,
            description: null,
            effectiveDate: null,
            internalName: null,
            tenantId: this.tenantId,
            downloadUrl: null
        };
    } 
}