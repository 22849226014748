import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { Observable } from "rxjs";
import { DetailComponent } from "./detail.component";

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateGuard> {

    canDeactivate(component: DetailComponent): boolean | Observable<boolean> | Promise<boolean> {
        return component.canDeactivate()            
    }
}