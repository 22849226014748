import { OnInit, Directive } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "app/shared/ignite/alert.service";
import { Auth0Service } from "../../+auth/auth0.service";
import { Permission } from "../../settings/users/permission";
import { I18nService } from "../i18n";
import { ApplicationContext } from "./application-context";
import { IDateFormatPreference } from "app/settings/preferences/date-format-preferences/date-format-preference.model";
import { IUserPreferences } from "app/settings/preferences/user-preferences/user-preferences.model";
import { IdNameValueObject } from "./id-name-value-object.model";
import { Restrictions } from "app/settings/users/restrictions";
import { IUser } from 'app/settings/users/user';


@Directive()
export abstract class BaseComponent implements OnInit {    
    public dateFormat: string = this.applicationContext.configurationService.dateFormat;
    public dateTimeFormat: string = this.applicationContext.configurationService.dateTimeFormat;
    public userDateFormatPreference: IDateFormatPreference;

    breadcrumbs: string[];

    constructor(protected applicationContext: ApplicationContext,
        protected activatedRoute: ActivatedRoute,
        protected formBuilder: FormBuilder) {

    }

    ngOnInit(): void {
        let userPrefs: IUserPreferences = JSON.parse(localStorage.getItem('userPreferences'));
        if (userPrefs && userPrefs.dateFormatPreference) {
            this.userDateFormatPreference = userPrefs.dateFormatPreference;

            if(userPrefs.dateFormatPreference.generalDateFormat)
            {
                this.dateFormat = userPrefs.dateFormatPreference.generalDateFormat;
            }            

            if (this.dateFormat == 'MM/dd/yyyy') {
                this.dateTimeFormat = this.dateFormat + " h:mm:ss a";
            } 
            else {
                this.dateTimeFormat = this.dateFormat + " HH:mm:ss";
            }
        }
        else {
            this.userDateFormatPreference = {
                generalDateFormat: this.dateFormat,
                kendoDateFormat: this.dateFormat
            }
        };
    }

    public get currentUser(): IUser {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    public get i18nService(): I18nService {
        return this.applicationContext.i18nService;
    }

    public get router(): Router {
        return this.applicationContext.router;
    }

    public get alertService(): AlertService {
        return this.applicationContext.alertService;
    }

    public get auth0Service(): Auth0Service {
        return this.applicationContext.auth0Service;
    }

    public get permissions(): Permission[] {
        return this.auth0Service.scopes;
    }

    public get userrestrictions(): Restrictions[] {
        return this.currentUser.restrictions;
    }

    public checkPermission(permission: Permission): boolean {

        if (!this.permissions) {
            return false;
        }

        return this.permissions.indexOf(permission) > -1;
    }

    public checkRestrictions(restriction: Restrictions): boolean {

        if (!this.userrestrictions) {
            return false;
        }

        return this.userrestrictions.indexOf(restriction) > -1;
    }

    public showLoading() {
        this.applicationContext.loadingService.show('main');
    }

    public hideLoading() {
        this.applicationContext.loadingService.hide('main');
    }

    keyDownFunction(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }
}