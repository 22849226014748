import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { I18nModule } from "../../i18n/i18n.module";
import { IgniteDropdownComponent } from "./ignite-dropdown.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        I18nModule,
        TooltipModule,
        BsDropdownModule.forRoot(),
    ],
    declarations: [
        IgniteDropdownComponent,
    ],
    exports: [
        IgniteDropdownComponent
    ],
    providers: []
})
export class IgniteDropdownModule { }