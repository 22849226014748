import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApplicationContext } from 'app/shared/ignite/application-context';
import { Permission } from '../../../settings/users/permission';
import { BaseComponent } from '../../ignite/base.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { CustomersService } from 'app/settings/customers/customers.service';
import { ISearchCriteria } from 'app/shared/ignite/search-criteria';
import { IdNameValueObject } from 'app/shared/ignite/id-name-value-object.model';


@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent extends BaseComponent implements OnInit, AfterViewInit {
  
  addonAudit: boolean;
  addOnCertBook: boolean;
  addonCertMap: boolean;
  canBrowseAsset: boolean;  
  canBrowseAssetTemplate: boolean;
  canBrowseAttachmentLabel: boolean;
  canBrowseAttributeUserGroups: boolean;
  canBrowseAttribute: boolean;
  canBrowseCategory: boolean;
  canBrowseCertCheckUser: boolean;  
  canBrowseContact: boolean;
  canBrowseCustomer: boolean;
  canBrowseCustomStatus: boolean;
  canBrowseDashboard: boolean;
  canBrowseDocument: boolean;
  canBrowseEcoCircle: boolean;
  canBrowseFixedReader: boolean;
  canBrowseForm: boolean;
  canBrowseFormTemplate: boolean;
  canBrowseFormType: boolean;
  canBrowseLicense: boolean;
  canBrowseLocation: boolean;
  canBrowseManufacturer: boolean;
  canBrowseReorder: boolean;
  canBrowseReport: boolean;
  canBrowseScheduledReport: boolean;  
  canExecuteAdminTasks: boolean;
  canBrowseTag: boolean;
  canBrowseTenantPreferences: boolean;
  canBrowseTenant: boolean;
  canBrowseTheme: boolean;
  canBrowseUser: boolean;
  canBrowseUserGroups: boolean;
  canBrowseWorkflowTemplate: boolean;
  reportChippedSlings: boolean;
  reportCompletedOpsFormsDetails: boolean;
  reportMultiColumnCert: boolean;
  reportSummarized: boolean;
  reportDescriptionQuantitySummary: boolean;
  reportLiftingRigging: boolean;
  reportDuplicatedAssetNumbers: boolean;
  reportWOValveSurvey: boolean;
  reportIncomingEquipament: boolean;
  reportCheckInOutHistory: boolean;
  canBrowseImportTool: boolean;
  canBrowseExportTool: boolean;
  canBrowseScheduledExportTool: boolean; 
  canBrowseCheckInOut: boolean;

  constructor(applicationContext: ApplicationContext,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: FormBuilder,
    private modalService: BsModalService,
    private customersService: CustomersService) {
    super(applicationContext, activatedRoute, formBuilder);
  }
  
  @ViewChild('welcomeTemplate') welcomeTemplate:TemplateRef<any>;
  modalRef?: BsModalRef; 
  @Output() onFinishTips: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    super.ngOnInit();

    this.addonAudit = this.checkPermission(Permission.AddonAudit);
    this.addOnCertBook = this.checkPermission(Permission.AddonCertBook);
    this.addonCertMap = this.checkPermission(Permission.AddonCertMap);
    this.canBrowseAsset = this.checkPermission(Permission.BrowseAsset);    
    this.canBrowseAssetTemplate = this.checkPermission(Permission.BrowseAssetTemplate);
    this.canBrowseAttachmentLabel = this.checkPermission(Permission.BrowseAttachmentLabel);
    this.canBrowseAttribute = this.checkPermission(Permission.BrowseAttribute);
    this.canBrowseCategory = this.checkPermission(Permission.BrowseCategory);    
    this.canBrowseContact = this.checkPermission(Permission.BrowseContact);
    this.canBrowseCustomer = this.checkPermission(Permission.BrowseCustomer);
    this.canBrowseDashboard = this.checkPermission(Permission.BrowseDashboard);// && this.checkPermission(Permission.AddonAnalytics);
    this.canBrowseCustomStatus = this.checkPermission(Permission.BrowseCustomStatus);
    this.canBrowseDocument = this.checkPermission(Permission.BrowseDocument);
    this.canBrowseEcoCircle = this.checkPermission(Permission.BrowseEcoCircle);
    this.canBrowseFixedReader = this.checkPermission(Permission.BrowseFixedReader);
    this.canBrowseForm = this.checkPermission(Permission.BrowseForm);
    this.canBrowseFormTemplate = this.checkPermission(Permission.BrowseFormTemplate);
    this.canBrowseFormType = this.checkPermission(Permission.BrowseFormType);
    this.canBrowseLicense = this.checkPermission(Permission.BrowseLicense);
    this.canBrowseLocation = this.checkPermission(Permission.BrowseLocation);
    this.canBrowseManufacturer = this.checkPermission(Permission.BrowseManufacturer);
    this.canBrowseReorder = this.checkPermission(Permission.BrowseReorder);
    this.canBrowseReport = this.checkPermission(Permission.BrowseReport);
    this.canBrowseScheduledReport = this.checkPermission(Permission.BrowseScheduledReport);    
    this.canExecuteAdminTasks = this.checkPermission(Permission.Administrator);
    this.canBrowseTag = this.checkPermission(Permission.BrowseTag);
    this.canBrowseTenant = this.checkPermission(Permission.BrowseTenant);
    this.canBrowseTenantPreferences = this.checkPermission(Permission.BrowseTenantPreferences);
    this.canBrowseTheme = this.checkPermission(Permission.BrowseTheme);
    this.canBrowseUser = this.checkPermission(Permission.BrowseUser);
    this.canBrowseUserGroups = this.checkPermission(Permission.BrowseUserGroups);
    this.canBrowseWorkflowTemplate = this.checkPermission(Permission.BrowseWorkflowTemplate);
    this.reportChippedSlings = this.checkPermission(Permission.ReportChippedSlings)
    this.reportCompletedOpsFormsDetails = this.checkPermission(Permission.ReportCompletedOpsFormsDetails);    
    this.reportMultiColumnCert = this.checkPermission(Permission.ReportMultiColumnCert);
    this.reportSummarized = this.checkPermission(Permission.ReportSummarized);
    this.reportDescriptionQuantitySummary = this.checkPermission(Permission.ReportDescriptionQuantitySummary);
    this.reportLiftingRigging = this.checkPermission(Permission.ReportLiftingRigging);
    this.reportDuplicatedAssetNumbers = this.checkPermission(Permission.ReportDuplicatedAssetNumbers);
    this.reportWOValveSurvey = this.checkPermission(Permission.ReportWOValveSurvey);
    this.reportCheckInOutHistory = this.checkPermission(Permission.ReportAssetCheckOutInHistory);
    this.reportIncomingEquipament = this.checkPermission(Permission.ReportIncomingEquipment);    

    this.canBrowseImportTool = this.checkPermission(Permission.ImportAsset) || this.checkPermission(Permission.ImportAssetTemplate) ||
                               this.checkPermission(Permission.ImportAttribute) || this.checkPermission(Permission.ImportCustomer) ||
                               this.checkPermission(Permission.ImportForm) || this.checkPermission(Permission.ImportFormTemplate) ||
                               this.checkPermission(Permission.ImportLocation) || this.checkPermission(Permission.ImportAssetAttachment);
                               
    this.canBrowseExportTool = this.checkPermission(Permission.ExportAsset) || this.checkPermission(Permission.ExportAssetTemplate) ||
                               this.checkPermission(Permission.ExportAttribute) || this.checkPermission(Permission.ExportCustomer) ||
                               this.checkPermission(Permission.ExportForm) || this.checkPermission(Permission.ExportFormTemplate) ||
                               this.checkPermission(Permission.ExportLocation) || this.checkPermission(Permission.ExportAssetAttachment);

    this.canBrowseScheduledExportTool = this.checkPermission(Permission.Administrator);

    this.canBrowseCheckInOut = this.checkPermission(Permission.CheckInAsset) || this.checkPermission(Permission.CheckOutAsset); 

    if (this.canBrowseUser){
      let criteria: ISearchCriteria = {searchCriteriaItems: [{condition: 'and', operation: '=', propertyName: 'isCertCheckAccessPrivate', value1: true, value2: null}]};
      this.customersService.projectWithTenant(criteria , this.applicationContext.auth0Service.currentUser.tenantId)
                         .subscribe(customers => {
                            this.canBrowseCertCheckUser = customers.length > 0;
                         },
                         error => {
                            this.alertService.error(error);
                            this.hideLoading();
                        });
    }
  } 

  ngAfterViewInit(): void {  
    if(localStorage.getItem("open-tessalink-web-tips") === null && this.modalRef == null){      
      localStorage.setItem("open-tessalink-web-tips", 'false');
      this.openModal(this.welcomeTemplate);      
    }  
  }

  notImplementedFeature() {
    this.alertService.warning(this.i18nService.getTranslation('Feature not available'));
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      backdrop: false,
      ignoreBackdropClick: true,
      class: 'tips-container' 
    });
  } 

  public onCloseModal(popWelcome: PopoverDirective, skip: boolean = false){
    // avoiding duplicated modals on load.
    this.modalRef?.hide();
    this.modalRef?.hide();

    if (skip)
      localStorage.setItem("open-tessalink-web-tips", "false");
    else
      popWelcome.show();
  };

  public finishTips(): void{    
    this.onFinishTips.next();  
  }

}
