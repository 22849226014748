import { ISearchCriteriaItem } from "../search-criteria-item";
import { ILocation } from "app/settings/locations/location";
import { IdNameValueObject } from "../id-name-value-object.model";
import { State } from "@progress/kendo-data-query";
import { ColumnConfiguration } from "app/settings/grid-configurations/grid-configurations.model";

export interface IListState {
        id: string;
        tenantId: string,
        listType: ListType;
        name: string;
        searchCriteriaItems: ISearchCriteriaItem[];
        location?: ILocation;
        manufacturer?: IdNameValueObject;
        owner?: IdNameValueObject;
        formType?: IdNameValueObject;
        contextId: string;
        columnsConfiguration: ColumnConfiguration[];
        listGridState?: State;
        formTemplate?: IdNameValueObject;
        checkedBy?: IdNameValueObject;
        isPrivated?: boolean;
        canEdit?: boolean;
        createdBy?: string;
        searchFormByCurrentAssetState?: boolean;
    }

export enum ListType {    
        Asset = 'Asset',
        Form = 'Form',        
        Schedule = 'Schedule'
}   