import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApplicationContext } from "app/shared/ignite/application-context";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ISearchCriteria } from "../../shared/ignite/search-criteria";
import { Service } from "../../shared/ignite/service";
import { ITenant, ITenantCompact } from "./tenant";
import { map } from 'rxjs/operators'




@Injectable()
export class TenantsService extends Service {
    private baseUrl: string;

    constructor(applicationContext: ApplicationContext,
        private httpClient: HttpClient) {
        super(applicationContext);

        //this.baseUrl = `${this.configService.apiBaseUrl}/tenant/`;
        this.baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/`
    }

    public getAll(): Observable<ITenant[]> {
        return this.httpClient.get<ITenant[]>(this.baseUrl)
            .pipe(catchError(this.handleError([])));
    }

    public getAllCompact(): Observable<ITenant[]> {
        return this.httpClient.get<ITenantCompact[]>(this.baseUrl + 'compact')
        .pipe(
            map((tenantCompacts: ITenantCompact[]) => 
            tenantCompacts.map(tenantCompact => <ITenant>
                { id: tenantCompact.id, corporateContact: {name: tenantCompact.name}, isLicensed: tenantCompact.isLicensed})))            
            .pipe(catchError(this.handleError([])));
    }   

    public getById(id: string): Observable<ITenant> {
        if (id === this.newRecordId) {
            return of(this.createEmpty());
        }

        return this.httpClient.get<ITenant>(this.baseUrl + id)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public getByIds(ids: string[]): Observable<ITenant[]> {

        return this.httpClient.post<ITenant[]>(this.baseUrl + "getByIds", ids, this.httpOptions)
            .pipe(catchError(this.handleError([])));
    }

    public getBranches(id: string): Observable<ITenant[]> {
        return this.httpClient.get<ITenant[]>(this.baseUrl + id + "/branches")
            .pipe(catchError(this.handleError([])));
    }

    public getBranchesRecursive(id: string): Observable<ITenant[]> {
        return this.httpClient.get<ITenant[]>(this.baseUrl + id + "/branches/recursive")
            .pipe(catchError(this.handleError([])));
    }

    public getProjectBranchesRecursive(id: string): Observable<ITenant[]> {
        return this.httpClient.get<ITenant[]>(this.baseUrl + id + "/branches/project/recursive")
            .pipe(catchError(this.handleError([])));
    }

    public getCustomers(id: string): Observable<ITenant[]> {
        return this.httpClient.get<ITenant[]>(this.baseUrl + id + "/customer")
            .pipe(catchError(this.handleError([])));
    }

    public getProjectCustomers(id: string): Observable<ITenant[]> {
        return this.httpClient.get<ITenant[]>(this.baseUrl + id + "/customer/project")
            .pipe(catchError(this.handleError([])));
    }

    public getParentsAndCustomers(id: string): Observable<ITenant[]> {
        return this.httpClient.get<ITenant[]>(this.baseUrl + id + "/parentAndCustomers")
            .pipe(catchError(this.handleError([])));
    }

    public getCustomersAndBranches(id?: string): Observable<ITenant[]> {
        if (!id) {
            id = this.tenantId;
        }

        return this.httpClient.get<ITenant[]>(this.baseUrl + id + "/customerAndBranches")
            .pipe(catchError(this.handleError([])));
    }

    public getProjectCustomersAndBranches(id?: string): Observable<ITenant[]> {
        if (!id) {
            id = this.tenantId;
        }

        return this.httpClient.get<ITenant[]>(this.baseUrl + id + "/customerAndBranches/project")
            .pipe(catchError(this.handleError([])));
    }

    public delete(id: string): Observable<Response> {
        return this.httpClient.delete(this.baseUrl + id, this.httpOptions)
            .pipe(catchError(this.handleError(null)));
    }

    public save(tenant: ITenant): Observable<ITenant> {
        if (tenant.id === this.newRecordId) {
            return this.httpClient.post<ITenant>(this.baseUrl, tenant, this.httpOptions)
                .pipe(catchError(this.handleError(this.createEmpty())));
        }

        return this.httpClient.put<ITenant>(this.baseUrl + tenant.id, tenant, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public optInManufacturer(tenantId: string, manufacturerId: string): Observable<ITenant> {       
        return this.httpClient.put<ITenant>(`${this.baseUrl}${tenantId}/optIn/manufacturer/${manufacturerId}`, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public optOutManufacturer(tenantId: string, manufacturerId: string): Observable<ITenant> {       
        return this.httpClient.put<ITenant>(`${this.baseUrl}${tenantId}/optOut/manufacturer/${manufacturerId}`, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public optInTag(tenantId: string, tagId: string): Observable<ITenant> {       
        return this.httpClient.put<ITenant>(`${this.baseUrl}${tenantId}/optIn/tag/${tagId}`, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public optOutTag(tenantId: string, tagId: string): Observable<ITenant> {       
        return this.httpClient.put<ITenant>(`${this.baseUrl}${tenantId}/optOut/tag/${tagId}`, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public optInAttribute(tenantId: string, attributeId: string): Observable<ITenant> {       
        return this.httpClient.put<ITenant>(`${this.baseUrl}${tenantId}/optIn/attribute/${attributeId}`, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public optOutAttribute(tenantId: string, attributeId: string): Observable<ITenant> {       
        return this.httpClient.put<ITenant>(`${this.baseUrl}${tenantId}/optOut/attribute/${attributeId}`, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public optOutCustomStatus(tenantId: string, customStatusId: string): Observable<ITenant> {       
        return this.httpClient.put<ITenant>(`${this.baseUrl}${tenantId}/optOut/customStatus/${customStatusId}`, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public search(searchCriteria: ISearchCriteria): Observable<ITenant[]> {
        return <Observable<ITenant[]>>this.httpClient.post<ITenant[]>(this.baseUrl + "search", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public project(searchCriteria: ISearchCriteria): Observable<ITenant[]> {
        return <Observable<ITenant[]>>this.httpClient.post<ITenant[]>(this.baseUrl + "project", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }    

    public searchOnlyParents(searchCriteria: ISearchCriteria): Observable<ITenant[]> {
        return <Observable<ITenant[]>>this.httpClient.post<ITenant[]>(this.baseUrl + "searchOnlyParents", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    private createEmpty(): ITenant {
        return {
            id: this.newRecordId,
            branches: [],
            branchesValues:[],
            corporateContact: {
                address: {
                    city: "",
                    country: "",
                    postalZip: "",
                    stateProvince: "",
                    street1: "",
                    street2: null,
                },
                description: null,
                email: "",
                isPrimary: true,
                name: "",
                phoneNumbers: []
            },
            customerOf: [],
            customerOfValues: [],
            notes: null,
            reorderContacts: [],
            settings: { 
                useNCodeFeature: false,
                useOnlyNewMobileUI: true,
                heartbeatReportEngagedCustomer: false,
                heartbeatReportIgnoredCustomer: false,
                heartbeatReportNewCustomer: false,
                heartbeatReportChurnedCustomer: false,
                heartbeatReportDemoCustomer: false
            },
            isCertNetCustomer: false,
            isCertCheckAccessPrivate: false
        };
    }
}