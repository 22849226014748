

export const languages: any[] = [
  {
    "key": "en-US",
    "alt": "English",
    "title": "English",
    "flagIcon": "us"
  },
  {
    "key": "es-MX",
    "alt": "Español (México)",
    "title": "Español (México)",
    "flagIcon": "mx"
  },
  {
    "key": "nn-NO",
    "alt": "Norwegian Nynorsk (Norway)",
    "title": "Norwegian Nynorsk (Norway)",
    "flagIcon": "no"
  },
  // {
  //   "key": "es",
  //   "alt": "Spanish",
  //   "title": "Español"
  // },
  // {
  //   "key": "fr",
  //   "alt": "France",
  //   "title": "Français"
  // },
  // {
  //   "key": "de",
  //   "alt": "German",
  //   "title": "Deutsch"
  // },
  // {
  //   "key": "jp",
  //   "alt": "Japan",
  //   "title": "日本語"
  // },
  // {
  //   "key": "cn",
  //   "alt": "China",
  //   "title": "中文"
  // },
  // {
  //   "key": "zh",
  //   "alt": "Chinese",
  //   "title": "汉语/漢語"
  // },
  {
    "key": "pt-BR",
    "alt": "Português (Brasil)",
    "title": "Português (Brasil)",
    "flagIcon": "br"
  }//,
  // {
  //   "key": "ru",
  //   "alt": "Russia",
  //   "title": "Русский язык"
  // },
  // {
  //   "key": "kr",
  //   "alt": "Korea",
  //   "title": "한국어"
  // }
];
