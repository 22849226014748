import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterContentInit, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Permission } from "../../../settings/users/permission";
import { ApplicationContext } from "../../ignite/application-context";
import { LayoutService } from "../layout.service";
import { UsersService } from 'app/settings/users/users.service';



@Component({
  selector: 'sa-shortcut',
  templateUrl: './shortcut.component.html',
  animations: [
    trigger('shortcutState', [
      state('out', style({
        height: 0,
      })),
      state('in', style({
        height: '*',
      })),
      transition('out => in', animate('250ms ease-out')),
      transition('in => out', animate('250ms 300ms ease-out'))
    ])
  ]
})
export class ShortcutComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {


  public state: string = 'out';

  private layoutSub: Subscription;
  private documentSub: any;

  canCreateAsset: boolean = false;
  canCreateForm: boolean = false;
  canBrownseReport: boolean = false;

  constructor(private layoutService: LayoutService,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef,    
    private appContext: ApplicationContext) {

    this.canCreateAsset = this.checkPermission(Permission.CreateAsset);
    this.canCreateForm = this.checkPermission(Permission.CreateForm);
    this.canBrownseReport = this.checkPermission(Permission.BrowseReport);
  }

  shortcutTo(route) {
    this.router.navigate(route);
    this.layoutService.onShortcutToggle(false);
  }

  ngOnInit() {

  }

  listen() {
    this.layoutSub = this.layoutService.subscribe((store) => {
      this.state = store.shortcutOpen ? 'in' : 'out'

      if (store.shortcutOpen) {
        this.documentSub = this.renderer.listen('document', 'mouseup', (event) => {
    if (!this.el.nativeElement.contains(event.target)) {
        this.layoutService.onShortcutToggle(false);
        this.documentUnsub();
    }
});
      } else {
        this.documentUnsub()
      }
    })
  }

  ngAfterContentInit() {
    this.listen()

  }


  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.layoutSub.unsubscribe();
  }


  documentUnsub() {
    this.documentSub && this.documentSub();
    this.documentSub = null
  }

  public checkPermission(permission: Permission): boolean {
    if (!this.appContext.auth0Service.scopes) {
      return false;
    }

    return this.appContext.auth0Service.scopes.indexOf(permission) > -1;
  }

  // userDetails(){
  //   this.appContext.loadingService.show('main');
  //   this.usersService.getByAuthenticationProviderUserId(this.appContext.auth0Service.userProfile.sub).subscribe(user => {
  //     this.router.navigate(['/settings/users/', user.id, user.tenantId]);
  //     this.appContext.loadingService.hide('main');
  //     this.layoutService.onShortcutToggle(false);
  //   },
  //   error => {
  //     this.appContext.alertService.error(this.appContext.i18nService.getTranslation('Logged user does not exist in database.'));
  //     this.appContext.loadingService.hide('main');
  //     this.layoutService.onShortcutToggle(false);
  //   })
  // }

}
