import { Component, Input, OnInit } from '@angular/core';
import { ApplicationContext } from '../application-context';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
})

export class LoadingComponent implements OnInit {
  @Input() name: string;
  isVisible = false;

  constructor(private appContext: ApplicationContext) { }

  ngOnInit() {
    if (this.name) {
      this.appContext.loadingService.registerInstance(this.name, this);
    }
  }

  ngOnDestroy() {
    if (this.name) {
      this.appContext.loadingService.removeInstance(this.name, this);
    }
  }

  /* ... code to show/hide this component */
  public hide() {
    this.isVisible = false;
  }

  public show() {
    this.isVisible = true;
  }

}
