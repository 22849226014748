import { AfterViewInit, Component, isDevMode, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Auth0Service } from './+auth/auth0.service';
import { VersionCheckService } from './shared/ignite/version-check.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet><div *ngIf="loading"><div class="loading-container"></div><span class="k-icon k-i-loading"></span></div>',
  styles: ['.loading-container{ position: fixed; top:0; left:0; width: 100%; height: 100%; background-color: white; opacity: 0.3; z-index: 1000;}',
    '.k-icon.k-i-loading { font-size: 64px; position: absolute; top: 50vh; left: 50vw; transform: translate(-50%, -50%); color: #3276b1; z-index: 1001;}']
})
export class AppComponent implements OnInit, AfterViewInit {
  loading : boolean;

  public constructor(private router: Router,
    public auth: Auth0Service,
    private versionCheckService: VersionCheckService) {
    //auth.scheduleRenewal();
  }

  ngOnInit(): void {
    this.loading = true;
  }

  ngAfterViewInit() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loading = true;
          if (!isDevMode()) {
            this.versionCheckService.checkVersion('version.json');
          }
        }
        else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          this.loading = false;
        }
      });
  }

}

//renderComponent(AppComponent as ComponentType<AppComponent>);

