import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'enumkeys' })
export class EnumKeysPipe implements PipeTransform {
    transform(value: any, args: string | string[]): any {
        let keys = [];
        for (var enumMember in value) {
            keys.push({ key: enumMember, value: value[enumMember] });
        }
        return keys;
    }
}