import { NgModule } from "@angular/core";
import { ActiveSessionsModule } from "./active-sessions/active-sessions.module";
import { Routing } from "./support.routing";
import { SynchronizationModule } from "./synchronization/synchronization.module";

@NgModule({
    declarations:[           
    ],    
    imports:[      
        ActiveSessionsModule,
        SynchronizationModule,     
        Routing
    ],
    providers:[                          
    ]
})

export class SupportModule {

}