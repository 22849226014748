import { Component, OnInit } from "@angular/core";
import { LoadTimeService } from "app/shared/ignite/load-time/load-time.service";


@Component({
    selector: 'load-time',
    templateUrl: './load-time.component.html',
})
export class LoadTimeComponent implements OnInit {

    public averageLoadingTime: string;
    public last10LoadingTimes: number[];

    constructor(private loadTimeService: LoadTimeService) {
    }

    ngOnInit() {
        this.loadTimeService.getExecutionTimes().subscribe(
            result => {
                //this.last10LoadingTimes = result.join(',');
                this.last10LoadingTimes = result;

                let sum = 0;
                result.forEach(element => {
                    sum = sum + (+element);
                });
                this.averageLoadingTime = (sum / 10).toFixed(3);
            }
        );
    }
}
