import { NotificationService } from "../utils/notification.service";
import { Injectable } from "@angular/core";

@Injectable()
export class AlertService {
    constructor(private notificationService: NotificationService) {
    }

    public smartMessageBox(data, cb?){
        this.notificationService.smartMessageBox(data, cb);
    }

    public error(content: string): void {
        this.notificationService.smallBox({
            title: "Error",
            content: content,
            color: "#953b39",
            iconSmall: "fa fa-times",
            timeout: 3000
        });
    }

    public success(content: string): void {
        this.notificationService.smallBox({
            title: "Success",
            content: content,
            color: "#356635",
            iconSmall: "fa fa-check",
            timeout: 1000
        });
    }

    public warning(content: string): void {
        this.notificationService.smallBox({
            title: "Warning",
            content: content,
            color: "#826430",
            iconSmall: "fa fa-warning",
            timeout: 2000
        });
    }
}