
import { RouterModule, Routes } from "@angular/router";
import { ScopeGuardService } from "../+auth/scope-guard.service";


export const routes: Routes = [
    {
        path: 'ecocircles',
        loadChildren: () => import('app/ecosystem/ecocircle/ecocircles.module').then(m => m.EcocirclesModule),
        canActivate: [ScopeGuardService],
        data: { pageTitle: 'EcoCircle', expectedScopes: ["browse:eco-circle"] }
    }
];

export const routing = RouterModule.forChild(routes);
