import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApplicationContext } from "app/shared/ignite/application-context";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ISearchCriteria } from "../../shared/ignite/search-criteria";
import { Service } from "../../shared/ignite/service";
import { IFormType } from "./form-type";


@Injectable()
export class FormTypesService extends Service {
    private baseUrl: string;

    constructor(applicationContext: ApplicationContext,
        private httpClient: HttpClient) {
        super(applicationContext);

        this.baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${this.tenantId}/formType/`;
    }

    public delete(id: string, tenantId?: string): Observable<IFormType> {
        let baseUrl = this.baseUrl;
        if (!tenantId) {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/formType/`;
        }
        else {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/formType/`;
        }

        return this.httpClient.delete(baseUrl + id, this.httpOptions)
            .pipe(catchError(this.handleError(null)));
    }

    public getAll(isGlobal?: boolean): Observable<IFormType[]> {
        let baseUrl = this.baseUrl;
        if (isGlobal === true) {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/formType/`;
        }

        return this.httpClient.get<IFormType[]>(baseUrl)
            .pipe(catchError(this.handleError([])));
    }

    public getById(id: string, tenantId?: string): Observable<IFormType> {
        let baseUrl;

        if (id === this.newRecordId) {
            return of(this.createEmpty());
        }

        if (!tenantId) {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/formType/`;
        }
        else {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/formType/`;
        }


        return this.httpClient.get<IFormType>(baseUrl + id)
            .pipe(catchError(this.handleError(null)));
    }

    public getByIds(ids: string[]): Observable<IFormType[]> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/formType/getByIds`;

        return this.httpClient.post<IFormType[]>(baseUrl, ids, this.httpOptions)
            .pipe(catchError(this.handleError([])));
    }

    public save(formType: IFormType, tenantId?: string): Observable<IFormType> {
        let baseUrl = this.baseUrl;
        if (!tenantId) {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/formType/`;
        }
        else {
            baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/formType/`;
        }

        if (formType.id == this.newRecordId) {
            return this.httpClient.post<IFormType>(baseUrl, formType, this.httpOptions)
                .pipe(catchError(this.handleError(this.createEmpty())));
        }

        return this.httpClient.put<IFormType>(baseUrl + formType.id, formType, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public search(searchCriteria: ISearchCriteria, tenantId: string = this.tenantId): Observable<IFormType[]> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/formType/`;
        return <Observable<IFormType[]>>this.httpClient.post<IFormType[]>(baseUrl + "search", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError([])));
    }

    public searchRecursive(searchCriteria: ISearchCriteria, tenantId: string = this.tenantId): Observable<IFormType[]> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/formType/`;
        return <Observable<IFormType[]>>this.httpClient.post<IFormType[]>(baseUrl + "search/recursive", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError([])));
    }

    public distribute(ids: string[], branchIds: string[]): Observable<any> {
        return <Observable<any>>this.httpClient.post<any>(this.baseUrl + "distribute", { ids: ids, branchIds: branchIds }, this.httpOptions)
            .pipe(catchError(this.handleError([])));
    }

    private createEmpty(): IFormType {
        return {
            id: this.newRecordId,
            name: "",
            description: null,
            isGlobal: false,
            recommendedAction: ""
        };
    }
}