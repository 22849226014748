import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Auth0Service } from 'app/+auth/auth0.service';
import { Subscription } from 'rxjs';
import { FadeZoomInTop } from "../../animations/fade-zoom-in-top.decorator";
import { I18nService } from '../../i18n';
import { HeaderComponent } from '../header';

@FadeZoomInTop()
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styles: []
})
export class MainLayoutComponent implements OnInit, OnDestroy {

  authSubscription: Subscription;
  @ViewChild(HeaderComponent) header: HeaderComponent;

  constructor(public auth: Auth0Service, public i18nService: I18nService) { }

  loggedIn: any;

  ngOnInit() {
    this.authSubscription = this.auth.loggedIn$
      .subscribe(loggedIn => this.loggedIn = loggedIn
      );
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  public onFinishNavigationTips(): void{    
    this.header.showTips();
  }  
}