import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoadTimeModule } from "app/shared/ignite/load-time/load-time.module";
import { AlertService } from "./alert.service";
import { CanDeactivateGuard } from "./can-deactivate.guard";
import { ConfigurationService } from "./configuration.service";
import { DropdownListModule } from "./dropdown-list/dropdown-list.module";
import { IgniteBreadcrumbModule } from "./ignite-breadcrumb/ignite-breadcrumb.module";
import { LoadingService } from "./loading.service";
import { IgniteUploadModule } from "./upload/upload.module";


@NgModule({
    exports: [
        LoadTimeModule,
        DropdownListModule,
        IgniteUploadModule,  
        IgniteBreadcrumbModule      
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadTimeModule,
        IgniteUploadModule,
        DropdownListModule,
        IgniteBreadcrumbModule
    ],
    providers: [
        AlertService,
        LoadingService,
        ConfigurationService,
        CanDeactivateGuard
    ]
})
export class IgniteModule {
}