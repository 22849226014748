import { Injectable } from '@angular/core';
import { UserProfile } from 'app/+auth/userprofile';
import * as auth0 from 'auth0-js';
import { BehaviorSubject, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UserPreferencesService } from '../settings/preferences/user-preferences/user-preferences.service';
import { Permission } from '../settings/users/permission';
import { ConfigurationService } from '../shared/ignite/configuration.service';
import { I18nService } from 'app/shared/i18n';
import { AlertService } from 'app/shared/ignite/alert.service';
import { UsersService } from 'app/settings/users/users.service';
import { IUser } from 'app/settings/users/user';
import { IUserIdentity } from './user-identity.model';

@Injectable()
export class Auth0Service {      
    // Create Auth0 web auth instance
    // @TODO: Update AUTH_CONFIG and remove .example extension in src/app/auth/auth0-variables.ts.example
    auth0: any;
    userProfile: UserProfile;
    scopes: Permission[];
    isWaitingOnAuth0: boolean = false;

    // Create a stream of logged in status to communicate throughout app
    loggedIn: boolean = false;
    loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);

    refreshSub: any;

    constructor(private configurationService: ConfigurationService,
                private alertService: AlertService,
                private usersService: UsersService,
                private userPreferecesService: UserPreferencesService,
                public i18nService: I18nService) {
        this.auth0 = new auth0.WebAuth({
            clientID: configurationService.clientID,
            domain: configurationService.domain,
            responseType: 'token',
            redirectUri: configurationService.callbackURL,
            audience: configurationService.audience,
            scope: configurationService.scope
        });

        // If authenticated, set local profile property and update login status subject
        if (this.authenticated) {
            this.userProfile = JSON.parse(localStorage.getItem('profile'));
            this.setLoggedIn(true);
        }
    }

    setLoggedIn(value: boolean) {
        // Update login status subject
        this.loggedIn$.next(value);
        this.loggedIn = value;
    }

    login() {
        var options = {
            theme: {
                logo: 'https://blobtlodev0.blob.core.windows.net/common/tessalink_noshadow_transparent_for_white_and_yellow_bgs.png'
            }
        };
        // Auth0 authorize request
        this.auth0.authorize(options);
    }     

    handleAuth() {
        // When Auth0 hash parsed, get profile
        this.isWaitingOnAuth0 = true;
        window.location.hash = window.location.hash.substring(window.location.hash.indexOf('#access_token'));
        this.auth0.parseHash({ hash: window.location.hash }, (err, authResult) => {
            if (authResult && authResult.accessToken) {
                window.location.hash = '';
                this._getProfile(authResult);
            } else if (err) {
                this.isWaitingOnAuth0 = false;
                console.error(`Error: ${err.error}`);
            }
        });
    }

    private _getProfile(authResult: { accessToken: any; }) {
        // Use access token to retrieve user's profile and set session
        this.auth0.client.userInfo(authResult.accessToken, (err: { error: any; }, profile: any) => {
            if (profile) {
                this._setSession(authResult, profile);          
            }
            else if (err) {
                this.isWaitingOnAuth0 = false;
                console.error(err.error);
            }
        });
    }

    private get _getScopes() {
        if (this.scopes) {
            return this.scopes;
        }

        const scopes = JSON.parse(localStorage.getItem('scopes'));
        if (scopes) {
            this.scopes = scopes;

            return this.scopes;
        }
    }

    private _setSession(authResult: { accessToken: any; expiresIn?: any; }, profile: UserProfile) {
        if (authResult && profile && (profile[this.configurationService.appMetadataNamespace].authorization || profile[this.configurationService.appMetadataNamespace].userIdentities)) {

            let hasGovPermission = profile[this.configurationService.appMetadataNamespace].userIdentities &&
                profile[this.configurationService.appMetadataNamespace].userIdentities.some(x => x.isGovUser == true);

            let hasComPermission = profile[this.configurationService.appMetadataNamespace].userIdentities &&
                profile[this.configurationService.appMetadataNamespace].userIdentities.some(x => x.isGovUser == false);

            if ((this.configurationService.isGovEnvironment === "true" && !hasGovPermission) ||
                (this.configurationService.isGovEnvironment === "false" && !hasComPermission)){

                this.alertService.error(this.i18nService.getTranslation('You do not have permission to access Tessalink Web app. Please contact support for more information.'));

                setTimeout(() => {
                    window.location.reload();                    
                }, 500);

                return;
            }

            const expTime = authResult.expiresIn * 1000 + Date.now();

            //const scopeFromToken = authResult.scope.replace(/%3A/g, ':') || '';
            //this.scopes = scopeFromToken.split(' ');
            if (profile[this.configurationService.appMetadataNamespace].userIdentities){
                localStorage.setItem('userIdentities', JSON.stringify(profile[this.configurationService.appMetadataNamespace].userIdentities));
                let activeIdentity = (<IUserIdentity[]>profile[this.configurationService.appMetadataNamespace].userIdentities)
                .find(n => n.tenantId === profile[this.configurationService.appMetadataNamespace].tenantId);

                this.scopes = activeIdentity?.permissions || [];
            }
            else
            {
                this.scopes = profile[this.configurationService.appMetadataNamespace].authorization.permissions;

                let identities = [{
                    isGovUser: profile[this.configurationService.appMetadataNamespace].isGovUser,
                    tenantId: profile[this.configurationService.appMetadataNamespace].tenantId,
                    permissions: this.scopes
                }];

                localStorage.setItem('userIdentities', JSON.stringify(identities));
            }     
            
            this.userProfile = profile;        
            localStorage.setItem('access_token', authResult.accessToken);
            localStorage.setItem('expiresIn', JSON.stringify(authResult.expiresIn));
            localStorage.setItem('expires_at', JSON.stringify(expTime));
            
            this.usersService.clearUserChache(profile.sub).subscribe(result =>{
                if (profile[this.configurationService.appMetadataNamespace].isGovUser.toString() !== this.configurationService.isGovEnvironment){
                    
                    var currentEnvIdentity = profile[this.configurationService.appMetadataNamespace].userIdentities.find(x => 
                        x.isGovUser.toString() === this.configurationService.isGovEnvironment);

                    localStorage.setItem('profile', JSON.stringify(this.userProfile));  

                    if (this.switchIdentity(currentEnvIdentity.tenantId)) {
                        return;
                    };                    
                }
                else {
                    this.getUserPreferences();      
                    this.usersService.getByAuthenticationProviderUserId(profile.sub, profile[this.configurationService.appMetadataNamespace].tenantId, this.scopes.some(n => n === Permission.ExecuteImpersonate))
                    .subscribe(dbUser => {

                        if (dbUser === null){
                            this.alertService.error("User not found!");
                            this.logout();
                            setTimeout(() => {
                                window.location.reload();                    
                            }, 500);
                        }
                                
                        profile.name = dbUser.firstName + " " + dbUser.lastName;
                        // Save session data and update login status subject           
                        localStorage.setItem('currentUser', JSON.stringify(dbUser));                        
                        localStorage.setItem('profile', JSON.stringify(this.userProfile));            
                        localStorage.setItem('scopes', JSON.stringify(this.scopes));

                        this.isWaitingOnAuth0 = false;
                        this.setLoggedIn(true);

                        this.scheduleRenewal();
                    },
                    error => {
                        this.alertService.error(error);
                        this.logout();
                    });
                }
            },
            error => {
                this.alertService.error(error);
                this.logout();
            });
        }
    }

    logout() {
        // Remove tokens and profile and update login status subject
        localStorage.removeItem('access_token');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('profile');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('scopes');
        localStorage.removeItem('userPreferences');
        localStorage.removeItem('userIdentities');

        sessionStorage.clear();

        this.unscheduleRenewal();

        this.scopes = undefined;
        this.userProfile = undefined;
        this.setLoggedIn(false);        
    }

    get authenticated(): boolean {
        // Check if current date is greater than expiration
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return Date.now() < expiresAt;
    }

    public userHasScopes(scopes: Array<Permission>): boolean {

        if (scopes) {
            return scopes.every(scope => this._getScopes.includes(scope));
        }
        return false;
    }

    public renewToken() {
        this.auth0.checkSession({}, (err: any, authResult: { accessToken: string; expiresIn: number; }) => {
            if (err) {
                console.error(err);
                this.unscheduleRenewal();
            } else {                
                localStorage.setItem('access_token', authResult.accessToken);
                const expTime = authResult.expiresIn * 1000 + Date.now();
                localStorage.setItem('expires_at', JSON.stringify(expTime));
                localStorage.setItem('expiresIn', JSON.stringify(authResult.expiresIn));
                console.log('Token renewaled')
            }
        });
    }

    // ..
    // define the refreshSubscription property
    refreshSubscription: any;

    // ...
    public scheduleRenewal() {        
        if (this.configurationService.authTokenRenewal !== true || !this.loggedIn) { return; }
        this.unscheduleRenewal();

        const expiresIn = JSON.parse(window.localStorage.getItem('expiresIn'));

        const expiresIn$ = of(expiresIn).pipe(
            mergeMap(
                expiresIn => {
                    //const now = Date.now();
                    // Use timer to track delay until expiration
                    // to run the refresh at the proper time
                    //return timer(Math.max(1, expiresAt - now));
                    let expireTime = ((expiresIn - 600) * 1000);
                    console.log(`Token renewal scheduled to ${expireTime} milliseconds`)
                    return timer(expireTime)
                    //return timer(60000);
                }
            )
        );

        // Once the delay time from above is
        // reached, get a new JWT and schedule
        // additional refreshes
        this.refreshSub = expiresIn$.subscribe(
            () => {
                this.renewToken();
                this.scheduleRenewal();
            }
        );
    }

    public unscheduleRenewal() {
        if (this.refreshSub) {
            this.refreshSub.unsubscribe();
        }
    }

    private getUserPreferences(reloadScreen: boolean = false) {
        this.userPreferecesService.GetUserPreferences().subscribe(preferences => {
            localStorage.setItem('userPreferences', JSON.stringify(preferences));
            localStorage.setItem('defaultSearchTextOperations', JSON.stringify(preferences.defaultSearchTextOperations));
            this.i18nService.setLanguage({ key: preferences.language });
            if (reloadScreen){
                window.location.reload();
            }
        })
    }    

    public get currentUser(): IUser {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    public get userIdentities(): IUserIdentity[] {
        return JSON.parse(localStorage.getItem('userIdentities')) || [];
    }

    switchIdentity(tenantId: string): boolean{
        let newIdentity: IUserIdentity = this.userIdentities.find(n => n.tenantId == tenantId);
        if (!newIdentity){
            this.alertService.error("Error swiching identity.");
            return false;
        }        

        this.userProfile = JSON.parse(localStorage.getItem('profile'));
        this.scopes = newIdentity.permissions;

        this.usersService.switchIdentity(this.userProfile.sub, tenantId)
                             .subscribe(dbUser => {

            if (dbUser === null){
                this.alertService.error("User not found!");
                this.logout();
            }
            
            sessionStorage.clear();
                        
            this.userProfile[this.configurationService.appMetadataNamespace].tenantId = tenantId;
            this.userProfile.name = dbUser.firstName + " " + dbUser.lastName;

            // Save session data and update login status subject
            localStorage.setItem('currentUser', JSON.stringify(dbUser));                        
            localStorage.setItem('profile', JSON.stringify(this.userProfile));            
            localStorage.setItem('scopes', JSON.stringify(this.scopes));

            this.getUserPreferences(true);                    
            
            //window.location.reload();           
        },
        error => {
            this.alertService.error(error);                
        });

    }

    switchEnvironment(): boolean{
        let newIdentity: IUserIdentity = this.userIdentities.sort((a: IUserIdentity, b: IUserIdentity) => {
            return new Date(b.lastLogin).getTime() - new Date(a.lastLogin).getTime();    
        }).find(n => n.isGovUser.toString() !== this.configurationService.isGovEnvironment);
                                                            
        if (!newIdentity){
            this.alertService.error("Error swiching Environment.");
            return false;
        }        

        this.usersService.switchIdentity(this.userProfile.sub, newIdentity.tenantId)
                         .subscribe(n => {                        
                            
            sessionStorage.clear();
            localStorage.clear();                                    
            this.logout();
            window.location.href = this.configurationService.alternativeLoginUrl;
            },
            error => {
                this.alertService.error(error);                
            });

    }

}