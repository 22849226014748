import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UploadModule } from "@progress/kendo-angular-upload";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { AttachmentsService } from "../../attachments/attachments.service";
import { I18nModule } from "../../i18n/i18n.module";
import { UploadComponent } from "./upload.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UploadModule,
        I18nModule,
        TooltipModule.forRoot(),
        ModalModule.forRoot()
    ],
    declarations: [
        UploadComponent,
    ],
    exports: [
        UploadComponent
    ],
    providers: [
        AttachmentsService,
    ],
    entryComponents: [
    ]
})
export class IgniteUploadModule { }