import { NgModule } from "@angular/core";
import { MomentPipe } from "./moment.pipe";
import { TimeDirective } from "./time.directive";
import { FieldFilterPipe } from './field-filter.pipe';
import { BodyService } from "./body.service";
import { NotificationService } from "./notification.service";
import { ToggleActiveDirective } from "./toggle-active.directive";
import { EnumKeysPipe } from "./enum-keys.pipe";
import { ModuleWithProviders } from "@angular/core";

@NgModule({
  declarations: [ToggleActiveDirective, MomentPipe, TimeDirective, FieldFilterPipe, EnumKeysPipe],
  exports: [ToggleActiveDirective, MomentPipe, TimeDirective, FieldFilterPipe, EnumKeysPipe],
  providers: [BodyService, NotificationService]
})
export class UtilsModule {
  static forRoot(): ModuleWithProviders<UtilsModule> {
    return {
        ngModule: UtilsModule,
        providers: [BodyService, NotificationService]
    };
}
}
