import { Input, OnInit, Component, Output, EventEmitter, ElementRef, ViewChild } from "@angular/core";
import { ApplicationContext } from "../application-context";
import { IListState, ListType } from "./list-state.model";
import { ListStateService } from "./list-state.service";

@Component({
    selector: 'app-list-state',
    templateUrl: './list-state.component.html',
    styles: ['.no-combo { margin-top: 4px;}']
})

export class ListStateComponent implements OnInit {    
        
    @Input() listType: ListType;
    @Output() onChangeHandler: EventEmitter<IListState> = new EventEmitter();   
    @Output() onChangeValueHandler = new EventEmitter();     
    
    @ViewChild('searchList') searchList: ElementRef;

    public listStates: IListState[];
    public filteredListStates: IListState[];
    public loading: boolean = false;

    public selectedValue: string;
    public get _selectedValue(){
        return this.filteredListStates?.find(n => n.id == this.selectedValue);
    }
    

    constructor(private applicationContext: ApplicationContext,                
        private listStateService: ListStateService) {       
    }

    ngOnInit() {
        this.fillListState(true);
    }    

    public fillListState(isFirstLoad: boolean = false) {
        this.loading = true;

        this.listStateService.getAll(this.listType).subscribe(list => {
            this.filteredListStates = this.listStates = list;            
            this.loading = false;

            if (isFirstLoad){
                let cachedValue = JSON.parse(sessionStorage.getItem('listState-' + this.listType));
                if (cachedValue)
                    this.onValueChange(cachedValue);
            }                        
        },
            error => {
                this.loading = false;
                this.applicationContext.alertService.error(error);
            }
        );
    }    

    public deleteFilter(): void {
        this.applicationContext.loadingService.show('main');
        this.listStateService.delete(this.selectedValue).subscribe(() => {
            this.selectedValue = null;

            this.fillListState();
            this.onChangeValueHandler.emit({ canSaveFilter: false, canDeleteFilter: false });            
            this.applicationContext.loadingService.hide('main');
        },
            error => {
                this.applicationContext.loadingService.hide('main');
                this.applicationContext.alertService.error(error);
            }
        );
    }   

    public saveFilter(newListState: IListState): void {
        this.applicationContext.loadingService.show('main');
        this.loading = true;

        let existingListState = this.listStates.find(n => n.id == newListState.id);
        if (existingListState)
            newListState.name = existingListState.name        
        else
            newListState.id = null;

        this.listStateService.save(newListState).subscribe(savedListState => {    
            this.fillListState();                        
            this.selectedValue = savedListState.id;
            sessionStorage.setItem('listState-' + this.listType, JSON.stringify(this.selectedValue));
            
            this.applicationContext.loadingService.hide('main');
            this.loading = false;
            this.applicationContext.alertService.success(this.applicationContext.i18nService.getTranslation('Search saved sucessfully.'));

            this.onChangeValueHandler.emit({ canSaveFilter: true, canDeleteFilter: true });
        },
            error => {
                this.loading = false;
                this.applicationContext.loadingService.hide('main');
                this.applicationContext.alertService.error(error);
            }
        );
    }   

    handleFilter(value: string) {
        if (value)
            this.filteredListStates = this.listStates.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        else
            this.filteredListStates = this.listStates;
    }

    public onValueChange(listStateId: string): void {        
        sessionStorage.removeItem('listState-' + this.listType);
        if (!listStateId || listStateId.trim() === "") {       
            this.selectedValue = null;     
            this.onChangeValueHandler.emit({ canSaveFilter: false, canDeleteFilter: false });            
            this.filteredListStates = this.listStates;
            this.onChangeHandler.emit(null);          
        } 
        else {
            this.selectedValue = listStateId;     
            if (this.listStates.some(n => n.id == this.selectedValue)) {                
                this.onChangeValueHandler.emit({ canSaveFilter: true, canDeleteFilter: true });        
            } else {
                this.onChangeValueHandler.emit({ canSaveFilter: true, canDeleteFilter: false });                                
            }

            let selectedItem = this.listStates.find(n => n.id == listStateId);
            if (selectedItem){
                sessionStorage.setItem('listState-' + this.listType, JSON.stringify(listStateId));
                this.onChangeHandler.emit(selectedItem);                
            }
        }        
    }

    public onShow(){        
        setTimeout(() => {
            this.searchList?.nativeElement?.focus();
        }, 0);
     }
    
}