import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { ApplicationContext } from '../../ignite/application-context';
import { LayoutService } from '../layout.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  userProfile: any;
  private keyword: string;

  @ViewChild("popQuickSearch") popQuickSearch: PopoverDirective;
  modalRef?: BsModalRef; 

  constructor(private router: Router,
    private applicationContext: ApplicationContext,
    private layoutService: LayoutService,
    private modalService: BsModalService) {
  }
  
  ngOnInit() {
    this.userProfile = this.applicationContext.auth0Service.userProfile;
  }  

  searchMobileActive = false;

  toggleSearchMobile() {
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    if (this.keyword){
      sessionStorage.setItem("main-search-type", "Asset");
      this.router.navigate(['/search-asset/' + encodeURIComponent(this.keyword)]);
      this.keyword = null;
    }
      
  }

  private keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.onSubmit();
    }
  }

  shortcutTo(route: any) {
    this.router.navigate(route);    
  }

  onToggle() {
    this.layoutService.onMinifyMenu()
  }

  public userLogout(){
    this.applicationContext.auth0Service.logout();
    this.router.navigate(['/auth/login'])
  }

  public showTips():void{
    this.popQuickSearch.show();
  }

  public finishTips(){
    this.modalRef.hide();
    localStorage.setItem("open-tessalink-web-tips", "false");
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef?.hide();
    this.modalRef = this.modalService.show(template, {
      backdrop: false,
      ignoreBackdropClick: true,
      class: 'tips-container' 
    });
  } 

  onShowAgain(){
    localStorage.removeItem("open-tessalink-web-tips");
    this.modalRef?.hide();
  }
}
