import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { JsonApiService } from "../../../../core/api/json-api.service";
import { ApplicationContext } from '../../../ignite/application-context';
import { Service } from '../../../ignite/service';
import { ITask } from './activities-task/task.model';

@Injectable()
export class ActivitiesService extends Service {

  private baseUrl: string;

  constructor(applicationContext: ApplicationContext,
    private httpClient: HttpClient,
    private jsonApiService: JsonApiService) {
    super(applicationContext)
    this.baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${this.tenantId}/task/`;
  }

  getActivities() {
    return this.jsonApiService.fetch('/activities/activities.json')
  }

  getAllTasks(): Observable<ITask[]> {
    return this.httpClient.get<ITask[]>(this.baseUrl + 'allTasks')
      .pipe(catchError(this.handleError([])));
  }  
}
