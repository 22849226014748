import { Component, Input } from "@angular/core";

@Component({
    selector: 'ignite-breadcrumb',
    templateUrl: './ignite-breadcrumb.component.html',    
    styleUrls: ['./ignite-breadcrumb.component.css']
})

export class IgniteBreadcrumbComponent {

    @Input() source: string[] = [];       
    @Input() iconName: string[] = [];       
       
}
