import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { Auth0Service } from "app/+auth/auth0.service";


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private auth: Auth0Service, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot) {
        if (!this.auth.authenticated) {
            this.router.navigate(['/auth/login']);
            return false;
        }

        return true;
    }
}