import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DropDownListModule, DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { DropdownListComponent } from "./dropdown-list.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DropDownListModule,
        DropDownsModule
    ],
    declarations: [
        DropdownListComponent,
    ],
    exports: [
        DropdownListComponent
    ],
    providers: [
    ]
})
export class DropdownListModule { }