import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Service } from 'app/+auth/auth0.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-callback',
    styleUrls: ['./callback.component.css'],
    templateUrl: './callback.component.html'
})
export class CallbackComponent implements OnInit, OnDestroy {
    loggedInSub: Subscription;

    constructor(private auth: Auth0Service, private router: Router) {
        this.auth.handleAuth();
        //this.auth.scheduleRenewal();
    }

    ngOnInit() {      
        this.loggedInSub = this.auth.loggedIn$.subscribe(
            loggedIn => {
                if (loggedIn) {                               
                    let href = localStorage.getItem('redirect-href');                    
                    if (href){
                        window.location.href = href;
                        localStorage.removeItem('redirect-href');
                    }
                    else {
                        this.router.navigate(['/home']);
                    }
                }
            })
    }

    ngOnDestroy() {
        this.loggedInSub.unsubscribe();
    }
}