import { IgniteUploadModule } from "app/shared/ignite/upload/upload.module";
import { SynchronizationComponent } from "./synchronization.component";
import { NgModule } from "@angular/core";
import { SmartadminModule } from "app/shared/smartadmin.module";
import { UploadModule } from "@progress/kendo-angular-upload";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TenantsService } from "app/settings/tenants/tenants.service";
import { IgniteDropdownModule } from "app/shared/ignite/ignite-dropdown/ignite-dropdown.module";
import { SynchronizationService } from "./synchronization.service";

@NgModule({
    imports:[
        IgniteUploadModule,        
        IgniteDropdownModule,
        SmartadminModule,
        UploadModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,        
    ],
    declarations:[
        SynchronizationComponent        
    ],        
    providers:[       
        SynchronizationService, 
        TenantsService
    ]
})

export class SynchronizationModule {}