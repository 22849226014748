import { Component, OnInit } from '@angular/core';
import { LayoutService } from "../../layout/layout.service";
import { ApplicationContext } from 'app/shared/ignite/application-context';
import { TenantsService } from 'app/settings/tenants/tenants.service';
import { IDropdownList } from 'app/shared/ignite/dropdown-list/dropdown-list.model';
import { Router } from '@angular/router';

@Component({

  selector: 'sa-login-info',
  templateUrl: './login-info.component.html',
})
export class LoginInfoComponent implements OnInit {

  public userProfile: any;
  public userIdentities:  IDropdownList[];
  public isLoadingprofiles: boolean;
  //public modalConfirmRef: BsModalRef;

  public get activeTenant(): string{
    return this.userIdentities?.find(n => n.id == this.tenantsService?.tenantId)?.name;
  }

  public get hasOtherEnvironmentIdentity(): string {
   if (this.applicationContext.auth0Service.userIdentities.some(n => n.isGovUser.toString() !== this.applicationContext.configurationService.isGovEnvironment)){
      return this.applicationContext.configurationService.isGovEnvironment === 'true' ? 
        'Most recent TESSALink login' : 
        'Most recent TESSALink Government login';
   }
   
   return null;
  }

  constructor(private applicationContext: ApplicationContext,
              private router: Router,
              private layoutService: LayoutService,    
              private tenantsService: TenantsService) {
  }

  ngOnInit() {
    this.userProfile = this.applicationContext.auth0Service.userProfile;   
    this.loadTenants();
  }

  toggleShortcut() {
    this.layoutService.onShortcutToggle()
  }

  navigateToUserPreferences(){
    this.applicationContext.router.navigate(["/settings/user-preferences"]);
  }

  navigateToTenantPreferences(){
    this.applicationContext.router.navigate(["/settings/tenant-preferences"]);
  }

  switchEnvironment(){
    this.applicationContext.auth0Service.switchEnvironment();
  }

  switchIdentity(tenantId: string){
    if (this.tenantsService.tenantId == tenantId){
      return;
    }

    this.applicationContext.router.navigate(["/home"]);
    this.applicationContext.auth0Service.switchIdentity(tenantId);
    // this.openConfirmModal("Switch Tenant", "You are about to change the Tenant Context. Are you sure?", null)
    //     .subscribe(result => {
    //       if (result){
    //         this.applicationContext.auth0Service.changeIdentity(tenantId);
    //       }
    //     })    
  }

  // private openConfirmModal(title: string, message: string, icon: string): Observable<boolean> {    
  //   let configObject: ModalOptions<any> = { initialState: { title: title, message: message, iconClass: icon } };
  //   this.modalConfirmRef = this.modalService.show(ConfirmModalComponent, configObject);    

  //   return Observable.create((observer: Observer<boolean>) => {  
  //     this.modalConfirmRef.content.onUpdateData.subscribe((confirmed: boolean) => {
  //       observer.next(confirmed);
  //     }); 
  //   });
  // }

  loadTenants(){
    if (!this.userIdentities){  

      if (!this.applicationContext.auth0Service.userIdentities ||this.applicationContext.auth0Service.userIdentities.length === 0){
        this.userIdentities = [];
      }

      this.isLoadingprofiles = true;  
      this.tenantsService.getByIds(this.applicationContext.auth0Service.userIdentities.filter(n => n.isGovUser.toString() === this.applicationContext.configurationService.isGovEnvironment)
                                                                                      .map(n => n.tenantId))
                          .subscribe(tenants => {
        this.userIdentities = tenants.map(n => <IDropdownList>{
                                                  id: n.id, name: n.corporateContact.name || `(${n.id})`, 
                                                  aditionalInformation: { 
                                                            isActive: n.id === this.tenantsService.tenantId, 
                                                            isGovUser:  this.applicationContext.auth0Service.userIdentities.find(x => x.tenantId === n.id)?.isGovUser
                                                          }
                                                }).sort((n1, n2) => {
                                                    if (n1.name.toLowerCase() > n2.name.toLowerCase()) {
                                                      return 1;
                                                    }
                                                    if (n1.name.toLowerCase() < n2.name.toLowerCase()) {
                                                      return -1;
                                                    }
                                                    return 0;
                                                });
        this.isLoadingprofiles = false;
      },
      error => {
        this.applicationContext.alertService.error(error);
        this.isLoadingprofiles = false;
      });
    }
  }

  logout(){
    this.applicationContext.auth0Service.logout();
    this.router.navigate(['/auth/login'])
  }

}
