import { ConfirmModalComponent } from "./confirm-modal.component";
import { NgModule } from "@angular/core";
import { SmartadminModule } from "../../shared/smartadmin.module";

@NgModule({
    declarations: [
        ConfirmModalComponent
    ],
    exports: [
        ConfirmModalComponent
    ],
    imports: [                
        SmartadminModule        
    ],
    entryComponents: [        
        ConfirmModalComponent
    ]
})
export class ConfirmModalModule {
}