import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { I18nModule } from "./i18n/i18n.module";
import { IgnitePopoverModule } from './ignite/ignite-popover/ignite-popover.module';
import { IgniteModule } from "./ignite/ignite.module";
import { SmartadminLayoutModule } from './layout';
import { EnumKeysPipe } from "./pipes/enum-keys.pipe";
import { SmartProgressbarModule } from "./ui/smart-progressbar/smart-progressbar.module";
import { UtilsModule } from "./utils/utils.module";
import { SmartadminWidgetsModule } from "./widgets/smartadmin-widgets.module";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,    
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
  ],
  declarations: [
    EnumKeysPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,    
    AlertModule,
    BsDropdownModule,
    ButtonsModule,
    ModalModule,
    ProgressbarModule,
    TabsModule,
    TooltipModule,
    EnumKeysPipe,
    IgnitePopoverModule,
    SmartadminLayoutModule,
    I18nModule,
    UtilsModule,    
    SmartProgressbarModule,
    SmartadminWidgetsModule,        
    IgniteModule    
  ],
  providers: [
  ]
})
export class SmartadminModule { }
