import { Component, Input, OnInit } from '@angular/core';
import { ApplicationContext } from '../../../../ignite/application-context';
import { ITask } from './task.model';

@Component({
  selector: '[activitiesTask]',
  templateUrl: './activities-task.component.html',
})
export class ActivitiesTaskComponent implements OnInit {

  @Input() item: ITask;
  dateFormat: string;

  constructor(private applicationContect: ApplicationContext) {
    this.dateFormat = applicationContect.configurationService.dateFormat;
  }

  ngOnInit() {
  }
}
