import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApplicationContext } from "app/shared/ignite/application-context";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Service } from "../../shared/ignite/service";
import { IActiveSession } from "./active-session.model";


@Injectable()
export class ActiveSessionsService extends Service {
    private baseUrl: string;

    constructor(applicationContext: ApplicationContext,
        private httpClient: HttpClient) {
        super(applicationContext);
        this.baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/sessions`;
    }   

    public getAll(): Observable<IActiveSession[]> {
        return this.httpClient.get<IActiveSession[]>(this.baseUrl)
            .pipe(catchError(this.handleError([])));
    }
  
    public clear(emailAddresses: string[]): Observable<IActiveSession[]> {        
        return <Observable<IActiveSession[]>>this.httpClient.post<IActiveSession[]>(this.baseUrl + "/clear", emailAddresses, this.httpOptions)
            .pipe(catchError(this.handleError([])));
    }      
}