import { Component, Input, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

@Component({
    templateUrl: "./confirm-modal.component.html"
})
export class ConfirmModalComponent implements OnInit {   

    @Input() title: string;
    @Input() message: string;
    @Input() additionalMessages: string[];
    @Input() yesButtonName: string = "Yes";
    @Input() noButtonName: string = "No";
    @Input() iconClass: string;

    public onUpdateData: Subject<boolean>;

    constructor(private _bsModalRef: BsModalRef) {}
    
    ngOnInit(): void {
        this.onUpdateData = new Subject();
    }

    onClose() {        
        this._bsModalRef.hide();
    }

    onNo() {
        this.onUpdateData.next(false);
        this._bsModalRef.hide();
    }

    onOk(){
        this.onUpdateData.next(true);
        this._bsModalRef.hide();
    }
}