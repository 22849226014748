import { RouterModule, Routes } from "@angular/router";
import { ScopeGuardService } from "app/+auth/scope-guard.service";
import { AuthGuard } from "app/+auth/auth-guard.service";


export const routes: Routes = [
  {
    path: 'export',
    loadChildren: () => import('app/data-export/export/data-export.module').then(m => m.DataExportModule),
    canActivate: [AuthGuard],
    /*canActivate: [ScopeGuardService],*/
    data: { pageTitle: 'Data Export', /*expectedScopes: ["browse:data-export"]*/ }
  },
  {
    path: 'scheduled',
    loadChildren: () => import('app/data-export/scheduled/scheduled-export.module').then(m => m.ScheduledExportModule),
    canActivate: [AuthGuard],
    /*canActivate: [ScopeGuardService],*/
    data: { pageTitle: 'Scheduled Export', /*expectedScopes: ["browse:scheduled-export"]*/ }
  }
];

export const routing = RouterModule.forChild(routes);