import { Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Auth0Service } from "app/+auth/auth0.service";
import { GridConfigurationsService } from "app/settings/grid-configurations/grid-configurations.service";
import { I18nService } from "app/shared/i18n";
import { AlertService } from "app/shared/ignite/alert.service";
import { ConfigurationService } from "app/shared/ignite/configuration.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { LoadTimeService } from "./load-time/load-time.service";
import { LoadingService } from "./loading.service";

@Injectable()
export class ApplicationContext implements OnInit {

    ngOnInit(): void {
    }

    public currentReportHeaderLogo: any;
    public currentReportFooterLogo: any;

    constructor(public configurationService: ConfigurationService,
        public i18nService: I18nService,
        public router: Router,
        public alertService: AlertService,
        public auth0Service: Auth0Service,
        public gridConfigurationService: GridConfigurationsService,
        public loadTimeService: LoadTimeService,
        public loadingService: LoadingService,
        public modalService: BsModalService) {        
    }
}
