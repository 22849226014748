import { Component } from "@angular/core";
import { ApplicationContext } from "app/shared/ignite/application-context";
import { BaseComponent } from "app/shared/ignite/base.component";

@Component({
    styleUrls: ["./download.component.css"],
    templateUrl: "./download.component.html"
})
export class DownloadComponent extends BaseComponent {
    desktopAppUrl: string;
    netFrameworkPrerequisitesUrl: string;
    testbenchAppUrl: string;
    playStoreUrl: string;
    appleStoreUrl: string;

    constructor(applicationContext: ApplicationContext) {
        super(applicationContext, null, null);

        this.desktopAppUrl = this.applicationContext.configurationService.configuration.desktopAppUrl;
        this.netFrameworkPrerequisitesUrl = this.applicationContext.configurationService.configuration.netFrameworkPrerequisitesUrl;
        this.testbenchAppUrl = this.applicationContext.configurationService.configuration.testbenchAppUrl;
        this.appleStoreUrl = this.applicationContext.configurationService.configuration.appleStoreUrl;
        this.playStoreUrl = this.applicationContext.configurationService.configuration.playStoreUrl;

        this.breadcrumbs = [
            this.applicationContext.i18nService.getTranslation("Download")
        ];
    }
}