import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Auth0Service } from 'app/+auth/auth0.service';
import { Permission } from 'app/settings/users/permission';
import { ApplicationContext } from 'app/shared/ignite/application-context';

@Injectable()
export class ScopeGuardService implements CanActivate {

    constructor(public auth: Auth0Service, public router: Router, private applicatgionContext: ApplicationContext) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {

        const scopes: Permission[] = (route.data as any).expectedScopes;

        if (!this.auth.authenticated || !this.auth.userHasScopes(scopes)) {
            if (this.auth.authenticated && !this.auth.userHasScopes([Permission.BrowseWebApp])) {
                this.applicatgionContext
                    .alertService
                    .warning(this.applicatgionContext
                        .i18nService
                        .getTranslation('You do not have permission to access Tessalink Web app. Please contact support for more information.'));
                this.auth.logout();
            }

            this.router.navigate(['/auth/login']);
            return false;
        }

        return true;
    }

}