import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Permission } from "app/settings/users/permission";
import { ApplicationContext } from "app/shared/ignite/application-context";
import { ListComponent } from "../../shared/ignite/list.component";
import { ActiveSessionsService } from "./active-sessions.service";


@Component({
    templateUrl: "./active-sessions-list.component.html"
})
export class ActiveSessionsListComponent extends ListComponent implements OnInit {

    public canBrowse: boolean;    

    constructor(applicationContext: ApplicationContext,
        protected activatedRoute: ActivatedRoute,
        protected formBuilder: FormBuilder,
        public activeSessionsService: ActiveSessionsService) {

        super(applicationContext, activatedRoute, formBuilder, "ActiveSessionsListComponent");
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.canBrowse = this.checkPermission(Permission.Administrator);              

        this.breadcrumbs = [
            this.i18nService.getTranslation("Active Sessions")
        ];

        this.search();
    }
    
    public search(): void {        

        this.showLoading();
        this.activeSessionsService.getAll()
            .subscribe(
                activeSessions => {
                    this.data = activeSessions;
                    this.hideLoading();
                },
                error => {
                    this.hideLoading();
                    this.alertService.error(error);
                }
            );
    }
   
    protected delete(email: string): void {
        if (confirm(this.i18nService.getTranslation("Are you sure you want to clear this Active Session?"))) {
            this.showLoading();
            this.activeSessionsService.clear([email])
                .subscribe(
                    activeSessions => {
                        this.hideLoading();
                        this.search();
                        this.alertService.success(this.i18nService.getTranslation("Active Session cleared successfully!"))
                    },
                    error => {
                        this.hideLoading();
                        this.alertService.error(error);
                    }
                );

        }
    }

    public deleteSelected(): void {
        if (confirm(this.i18nService.getTranslation("Are you sure you want to clear all selected Active Sessions?"))) {
            this.showLoading();
            this.activeSessionsService.clear(this.selectedIndexes)
                .subscribe(
                    activeSessions => {
                        this.hideLoading();
                        this.search();
                        this.alertService.success(this.i18nService.getTranslation("Active Session cleared successfully!"))
                    },
                    error => {
                        this.hideLoading();
                        this.alertService.error(error);
                    }
                );

        }
    }       
}