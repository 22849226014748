import { NgModule } from "@angular/core";
import { routing } from "./reports.routing";

@NgModule({
    declarations: [        
    ],
    exports: [
    ],
    imports: [
        routing        
    ]    
})
export class ReportsModule {
}