
import { RouterModule, Routes } from "@angular/router";
import { ScopeGuardService } from "../+auth/scope-guard.service";


export const routes: Routes = [
  {
    path: 'attachment-labels',
    loadChildren: () => import('app/settings/attachment-labels/attachment-labels.module').then(m => m.AttachmentLabelsModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Attachment Labels', expectedScopes: ["browse:attachment-label"] }
  },
  {
    path: 'attributes',
    loadChildren: () => import('app/settings/attributes/attributes.module').then(m => m.AttributesModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Attributes', expectedScopes: ["browse:attribute"] }
  },
  {
    path: 'customers',
    loadChildren: () => import('app/settings/customers/customers.module').then(m => m.CustomersModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Customers', expectedScopes: ["browse:customer"] }
  },
  {
    path: 'fixed-readers',
    loadChildren: () => import('app/settings/fixed-readers/fixed-readers.module').then(m => m.FixedReadersModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Fixed Readears', expectedScopes: ["browse:fixed-reader"] }
  },
  {
    path: 'form-templates',
    loadChildren: () => import('app/settings/form-templates/form-templates.module').then(m => m.FormTemplatesModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Form Templates', expectedScopes: ["browse:form-template"] }
  },
  {
    path: 'form-types',
    loadChildren: () => import('app/settings/form-types/form-types.module').then(m => m.FormTypesModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Form Types', expectedScopes: ["browse:form-type"] }
  },
  {
    path: 'licenses',
    loadChildren: () => import('app/settings/licenses/licenses.module').then(m => m.LicensesModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Licenses', expectedScopes: ["browse:license"] }
  },
  {
    path: 'locations',
    loadChildren: () => import('app/settings/locations/locations.module').then(m => m.LocationsModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Locations', expectedScopes: ["browse:location"] }
  },
  {
    path: 'manufacturers',
    loadChildren: () => import('app/settings/manufacturers/manufacturers.module').then(m => m.ManufacturersModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Manufacturers', expectedScopes: ["browse:manufacturer"] }
  },
  {
    path: 'tenant-preferences',
    loadChildren: () => import('app/settings/preferences/tenant-preferences/tenant-preferences.module').then(m => m.TenantPreferencesModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Tenant Preferences', expectedScopes: ["browse:tenant-preferences"] }
  },
  {
    path: 'tenants',
    loadChildren: () => import('app/settings/tenants/tenants.module').then(m => m.TenantsModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Tenants', expectedScopes: ["browse:tenant"] }
  },
  {
    path: 'themes',
    loadChildren: () => import('app/settings/themes/themes.module').then(m => m.ThemesModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Themes', expectedScopes: ["browse:theme"] }
  },
  {
    path: 'user-groups',
    loadChildren: () => import('app/settings/user-groups/user-groups.module').then(m => m.UserGroupsModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'User Groups', expectedScopes: ["browse:user-groups"] }
  },
  {
    path: 'user-preferences',
    loadChildren: () => import('app/settings/preferences/user-preferences/user-preferences.module').then(m => m.UserPreferencesModule),
    data: { pageTitle: 'User Preferences' }
  },
  {
    path: 'users',
    loadChildren: () => import('app/settings/users/users.module').then(m => m.UsersModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Users', expectedScopes: ["browse:user"] }
  },
  {
    path: 'cert-check-users',
    loadChildren: () => import('app/settings/cert-check-users/cert-check-users.module').then(m => m.CertCheckUsersModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'CertCheck Users', expectedScopes: ["browse:user"] }
  },
  {
    path: 'workflows',
    loadChildren: () => import('app/settings/workflows/workflows.module').then(m => m.WorkflowsModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Workflows', expectedScopes: ["browse:workflow"] }
  },
  {
    path: 'workflow-templates',
    loadChildren: () => import('app/settings/workflow-templates/workflow-templates.module').then(m => m.WorkflowTemplatesModule),
    canActivate: [ScopeGuardService],
    data: { pageTitle: 'Workflow Templates', expectedScopes: ["browse:workflow-template"] }
  },
];

export const routing = RouterModule.forChild(routes);
