import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SmartadminModule } from "app/shared/smartadmin.module";
import { IgniteBreadcrumbComponent } from "./ignite-breadcrumb.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule                 
    ],
    declarations: [
        IgniteBreadcrumbComponent
    ],
    providers:[                
    ],
    exports:[
        IgniteBreadcrumbComponent
    ]
})
export class IgniteBreadcrumbModule { }