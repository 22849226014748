import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadTimeService } from 'app/shared/ignite/load-time/load-time.service';
import { AuthenticationTokenInterceptor } from './+auth/auth.interceptor';
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from './app.routing';
import { AppState, InternalStateType } from './app.service';
import { AssetManagementModule } from './asset-management/asset-management.module';
// Core providers
import { CoreModule } from "./core/core.module";
import { EcoSystemModule } from './ecosystem/ecosystem.module';
import { ReportsModule } from './reports/reports.module';
import { SettingsModule } from './settings/settings.module';
import { ConfigurationService } from './shared/ignite/configuration.service';
import { IgniteModule } from './shared/ignite/ignite.module';
import { VersionCheckService } from './shared/ignite/version-check.service';
import { SupportModule } from './support/support.module';
import { TimeZoneInterceptor } from './interceptors/time-zone.interceptor';
import { ExportModule } from './data-export/export.module';
import { IgniteBreadcrumbModule } from './shared/ignite/ignite-breadcrumb/ignite-breadcrumb.module';


// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  AppState
];

let configurationLoad = (configurationService: ConfigurationService) => () => configurationService.load();

type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent
  ],
  imports: [ // import Angular's modules
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    EcoSystemModule,
    IgniteModule,
    SettingsModule,
    AssetManagementModule,
    ReportsModule,
    SupportModule,
    ExportModule,
    routing
  ],
  exports: [
  ],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    // ENV_PROVIDERS,
    APP_PROVIDERS,
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: configurationLoad,
      deps: [ConfigurationService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeZoneInterceptor,
      multi: true
    },
    LoadTimeService,
    VersionCheckService,
  ]
})
export class AppModule {
  constructor(public appRef: ApplicationRef, public appState: AppState) {
  }
}

