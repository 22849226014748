import { NgModule } from "@angular/core";

import { routing } from "./settings.routing";
import { FormTypesService } from "./form-types/form-types.service"

@NgModule({
    providers: [
        FormTypesService,
    ],
    declarations: [
    ],
    exports: [
    ],
    imports: [
        routing,
    ]
})
export class SettingsModule {
}