
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpHeaders } from "@angular/common/http";
import { ApplicationContext } from "./application-context";


export abstract class Service {
    constructor(private _applicationContext: ApplicationContext) {
    }

    public get tenantId(): string {
        if (!this.applicationContext?.auth0Service?.userProfile)
            return null;

        return this.applicationContext?.auth0Service?.userProfile[this.applicationContext.configurationService.appMetadataNamespace]?.tenantId;
    }

    public get newRecordId(): string {
        return "new";
    }

    protected get httpOptions(): object {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
    }

    protected get applicationContext(): ApplicationContext {
        return this._applicationContext;
    }

    protected handleError<T>(result?: T) {
        return (error: any): Observable<T> => {
            let errorMsg = "The application has encountered an unknown error. If the error persists, please contact the support team.";
            
            if (error && (error.status === 403 || error.status === 401)){
                return observableThrowError("You do not have permission to perform this action, please contact the support team");
            }   
            
            if (!error.error){
                return observableThrowError(errorMsg);
            }   
           
            return observableThrowError(error.error.type && error.error.type === "error" ? errorMsg : 
            error.error );
        };
    }
}