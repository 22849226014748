import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApplicationContext } from "app/shared/ignite/application-context";
import { ConfigurationService } from "app/shared/ignite/configuration.service";
import { Service } from "app/shared/ignite/service";
import { throwError as observableThrowError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { IGridConfigurations } from "./grid-configurations.model";


@Injectable()
export class GridConfigurationsService {
    private baseUrl: string;

    constructor(configurationService: ConfigurationService,
        private httpClient: HttpClient){
        this.baseUrl = `${configurationService.apiBaseUrl}/tenant/`;
    }

    public getById(userId: string, nameGrid: string, tenantId: string): Observable<IGridConfigurations> {
        let baseUrl: string = this.baseUrl;
        if (tenantId) {
            baseUrl = `${this.baseUrl}${tenantId}/gridConfiguration/user/`;
        }

        return this.httpClient.get<IGridConfigurations>(baseUrl + userId + "/nameGrid/" + nameGrid)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    protected handleError<T>(result?: T) {
        return (error: any): Observable<T> => {
            return observableThrowError(error.error ? error.error : error.statusText);
        };
    }

    public get newRecordId(): string {
        return "new";
    }


    protected get httpOptions(): object {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
    }

    public save(gridConfiguration: IGridConfigurations, tenantId?: string): Observable<IGridConfigurations> {
        let baseUrl = this.baseUrl;
        if (tenantId) {
            baseUrl = `${this.baseUrl}${tenantId}/gridConfiguration/`;
        }

        if (gridConfiguration.id === this.newRecordId) {
            return this.httpClient.post<IGridConfigurations>(baseUrl, gridConfiguration, this.httpOptions)
                .pipe(catchError(this.handleError(this.createEmpty())));
        }

        return this.httpClient.put<IGridConfigurations>(baseUrl + gridConfiguration.id, gridConfiguration, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public createEmpty(): IGridConfigurations {
        return {
            id: this.newRecordId,
            name: null,
            userId: null,
            columnsConfiguration: [],
            state: null
        };
    }
}