import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationContext } from '../../../ignite/application-context';
import { ITask } from './activities-task/task.model';
import { ActivitiesService } from "./activities.service";

declare var $: any;

@Component({
  selector: 'sa-activities',
  templateUrl: './activities.component.html',
  providers: [ActivitiesService],
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  count: number;
  lastUpdate: any;
  active: boolean;
  currentActivity: string;
  loading: boolean;

  myTasks: ITask[] = [];
  allTasks: ITask[] = [];


  constructor(
    private applicationContext: ApplicationContext,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    private activitiesService: ActivitiesService,
  ) {
    this.active = false;
    this.loading = false;
    this.count = 0;
    this.lastUpdate = new Date();
  }

  ngOnInit() {
    this.getTasks();
    if (this.applicationContext.configurationService.automaticUpdateTasks) {
      setInterval(() => {
        this.getTasks();
      }, this.applicationContext.configurationService.taskIntervalTime)
    }
  }

  private getTasks() {
    this.loading = true;
    this.activitiesService.getAllTasks().subscribe(tasks => {
      this.myTasks = tasks.filter(n => n.myselfOnly == true);
      this.allTasks = tasks.filter(n => n.myselfOnly == false);
      this.count = tasks.length;
      this.currentActivity = 'myTasks';
      this.loading = false;
      this.lastUpdate = new Date();
    },
      error => {
        this.applicationContext.alertService.error(error);
        this.loading = false;
      });
  }

  setActivity(activity: string) {
    this.currentActivity = activity;
  }

  private documentSub: any;
  onToggle() {
    let dropdown = $('.ajax-dropdown', this.el.nativeElement);
    this.active = !this.active;
    if (this.active) {
      dropdown.fadeIn()


      this.documentSub = this.renderer.listen('document', 'mouseup', (event) => {
    if (!this.el.nativeElement.contains(event.target)) {
        dropdown.fadeOut();
        this.active = false;
        this.documentUnsub();
    }
});


    } else {
      dropdown.fadeOut()

      this.documentUnsub()
    }
  }



  update() {
    this.getTasks();
  }


  ngOnDestroy() {
    this.documentUnsub()
  }

  documentUnsub() {
    this.documentSub && this.documentSub();
    this.documentSub = null
  }

  private navigateToAssetList(listId: string) {
    this.onToggle();
    this.router.navigate(["/asset-management/assets"], { queryParams: { assetListId: listId } });
  }

}
