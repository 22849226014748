import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChartsModule } from "@progress/kendo-angular-charts";
import { I18nModule } from "app/shared/i18n/i18n.module";
import { LoadTimeComponent } from "app/shared/ignite";


@NgModule({
    declarations: [
        LoadTimeComponent
    ],
    exports: [
        LoadTimeComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ChartsModule,
        I18nModule
    ],
    providers: [
        //LoadTimeService
    ]
})
export class LoadTimeModule {
}