import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApplicationContext } from "app/shared/ignite/application-context";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ISearchCriteria } from "../../shared/ignite/search-criteria";
import { Service } from "../../shared/ignite/service";
import { ITenant } from "../tenants/tenant";
import { IConfirmCustomersBrandingModal } from "./confirm-customers-branding-modal.model";





@Injectable()
export class CustomersService extends Service {

    constructor(applicationContext: ApplicationContext,
        private httpClient: HttpClient) {
        super(applicationContext);
    }

    public getAll(tenantId: string = this.tenantId): Observable<ITenant[]> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/customer/`;

        return this.httpClient.get<ITenant[]>(baseUrl)
            .pipe(catchError(this.handleError([])));
    }

    public getCustomerById(id: string, tenantId: string = this.tenantId): Observable<ITenant> {
        if (id === this.newRecordId) {
            return of(this.createEmpty());
        }

        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/customer/`;

        return this.httpClient.get<ITenant>(baseUrl + id)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public getById(id: string): Observable<ITenant> {
        if (id === this.newRecordId) {
            return of(this.createEmpty());
        }

        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/`;

        return this.httpClient.get<ITenant>(baseUrl + id)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public delete(id: string, tenantId: string = this.tenantId): Observable<ITenant> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/customer/`;

        return this.httpClient.delete(baseUrl + id, this.httpOptions)
            .pipe(catchError(this.handleError(null)));
    }

    
    public save(customer: ITenant, tenantId: string = this.tenantId): Observable<ITenant> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/customer/`;

        if (customer.id == this.newRecordId) {
            return this.httpClient.post<ITenant>(baseUrl, customer, this.httpOptions)
                .pipe(catchError(this.handleError(this.createEmpty())));
        }

        return this.httpClient.put<ITenant>(baseUrl + customer.id, customer, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public saveWithBrandingOptions(customer: ITenant, tenantId: string = this.tenantId, customerBranding: IConfirmCustomersBrandingModal): Observable<ITenant> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/customerBrandingOptions/`;

        let tenantInputMessage = {
            customerBrandingOptions: customerBranding,
            customerData: customer
          };

        if (customer.id == this.newRecordId) {
            return this.httpClient.post<ITenant>(baseUrl, tenantInputMessage, this.httpOptions)
                .pipe(catchError(this.handleError(this.createEmpty())));
        }

        return this.httpClient.put<ITenant>(baseUrl + customer.id, customer, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public search(searchCriteria: ISearchCriteria, tenantId: string = this.tenantId): Observable<ITenant[]> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/customer/`;

        return <Observable<ITenant[]>>this.httpClient.post<ITenant[]>(baseUrl + "search", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public project(searchCriteria: ISearchCriteria, includeMyTenant: boolean = false, tenantId: string = this.tenantId): Observable<ITenant[]> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/customer/`;

        return <Observable<ITenant[]>>this.httpClient.post<ITenant[]>(baseUrl + "project?includeMyTenant=" + includeMyTenant , searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public searchRecursive(searchCriteria: ISearchCriteria, tenantId: string = this.tenantId): Observable<ITenant[]> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/customer/`;

        return <Observable<ITenant[]>>this.httpClient.post<ITenant[]>(baseUrl + "search/recursive", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public projectRecursive(searchCriteria: ISearchCriteria, tenantId: string = this.tenantId): Observable<ITenant[]> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/customer/`;

        return <Observable<ITenant[]>>this.httpClient.post<ITenant[]>(baseUrl + "project/recursive", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public searchWithTenant(searchCriteria: ISearchCriteria, tenantId: string = this.tenantId): Observable<ITenant[]> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/customer/`;

        return <Observable<ITenant[]>>this.httpClient.post<ITenant[]>(baseUrl + "search?includeMyTenant=true", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public projectWithTenant(searchCriteria: ISearchCriteria, tenantId: string = this.tenantId): Observable<ITenant[]> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/customer/`;

        return <Observable<ITenant[]>>this.httpClient.post<ITenant[]>(baseUrl + "project?includeMyTenant=true", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public projectMultipleWithTenant(searchCriteria: ISearchCriteria, tenantIds: string[]): Observable<ITenant[]> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${this.tenantId}/customer/`;

        return <Observable<ITenant[]>>this.httpClient.post<ITenant[]>(`${baseUrl}projectMultiple?tenantIds=${tenantIds}&includeMyTenant=true`, searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public searchRecursiveWithTenant(searchCriteria: ISearchCriteria, tenantId: string = this.tenantId): Observable<ITenant[]> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/customer/`;

        return <Observable<ITenant[]>>this.httpClient.post<ITenant[]>(baseUrl + "search/recursive?includeMyTenant=true", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public projectRecursiveWithTenant(searchCriteria: ISearchCriteria, tenantId: string = this.tenantId): Observable<ITenant[]> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/customer/`;

        return <Observable<ITenant[]>>this.httpClient.post<ITenant[]>(baseUrl + "project/recursive?includeMyTenant=true", searchCriteria, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public isTenantOnMyBrachHierarchy(id: string, recursive: boolean = false): Observable<boolean> {
       
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${id}`;

        return this.httpClient.get<boolean>(`${baseUrl}/isTenantOnMyBranchHierarchy?recursive=${recursive}`)
            .pipe(catchError(this.handleError(false)));
    }

    private createEmpty(): ITenant {
        return {
            id: this.newRecordId,
            corporateContact: {
                address: {
                    city: "",
                    country: "",
                    postalZip: "",
                    stateProvince: "",
                    street1: "",
                    street2: null,
                },
                description: null,
                email: "",
                isPrimary: true,
                name: "",
                phoneNumbers: []
            },
            customerOf: [],
            customerOfValues: [],
            branches: [],
            branchesValues: [],
            notes: null,
            reorderContacts: [],
            settings: { 
                useNCodeFeature: false,
                useOnlyNewMobileUI: false, 
                heartbeatReportEngagedCustomer: false,
                heartbeatReportIgnoredCustomer: false,
                heartbeatReportNewCustomer: false,
                heartbeatReportChurnedCustomer: false,
                heartbeatReportDemoCustomer: false
            },
            isCertNetCustomer: false,
            isCertCheckAccessPrivate: false
        };
    }
}