/**
 * Created by griga on 7/11/16.
 */

import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from 'app/callback/callback.component';
import { DownloadComponent } from 'app/download/download.component';
import { ScopeGuardService } from './+auth/scope-guard.service';
import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthGuard } from "./+auth/auth-guard.service";

export const routes: Routes = [
    {
        path: '',
        component: MainLayoutComponent,
        data: { pageTitle: 'Home', expectedScopes: ["browse:web-app"] },
        canActivate: [ScopeGuardService],
        children: [
            {
                path: '', redirectTo: 'home', pathMatch: 'full',
            },
            { path: 'home', loadChildren: () => import('app/+home/home.module').then(m => m.HomeModule), data: { pageTitle: 'Home' } },
            { path: 'add-ons', loadChildren: () => import('app/add-ons/add-ons.module').then(m => m.AddOnsModule), data: { pageTitle: 'Add-Ons' } },
           // { path: 'safety', loadChildren: () => import('app/safety/safety.module').then(m => m.SafetyModule), data: { pageTitle: 'Safety' } },
            { path: 'settings', loadChildren: () => import('app/settings/settings.module').then(m => m.SettingsModule), data: { pageTitle: 'Settings' } },
            { path: 'reports', loadChildren: () => import('app/reports/reports.module').then(m => m.ReportsModule), data: { pageTitle: 'Reports' } },
            { path: 'dashboard', loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [ScopeGuardService], data: { pageTitle: 'Dashboard', expectedScopes: ["browse:dashboard"] } },
            { path: 'asset-management', loadChildren: () => import('app/asset-management/asset-management.module').then(m => m.AssetManagementModule), data: { pageTitle: 'Asset Management' } },
            { path: 'download', component: DownloadComponent, data: { pageTitle: 'Download' } },
            { path: 'search-asset', loadChildren: () => import('app/main-search/main-search-asset/main-search-asset.module').then(m => m.MainSearchAssetModule), data: { pageTitle: 'Asset Search' } },
            { path: 'search-form', loadChildren: () => import('app/main-search/main-search-form/main-search-form.module').then(m => m.MainSearchFormModule), data: { pageTitle: 'Form Search' } },
            { path: 'search-schedule', loadChildren: () => import('app/main-search/main-search-schedule/main-search-schedule.module').then(m => m.MainSearchSchedulesModule), data: { pageTitle: 'Schedule Search' } },
            { path: 'ecosystem', loadChildren: () => import('app/ecosystem/ecosystem.module').then(m => m.EcoSystemModule), data: { pageTitle: 'EcoSystem' } },
            { path: 'support', loadChildren: () => import('app/support/support.module').then(m => m.SupportModule), data: { pageTitle: 'Support' } },
            { path: 'process-queue', loadChildren: () => import('app/jobs/jobs.module').then(m => m.JobsModule), canActivate: [AuthGuard], data: { pageTitle: 'Process Queue' } },
            { path: 'data-import', loadChildren: () => import('app/data-import/data-import.module').then(m => m.DataImportModule), data: { pageTitle: 'Data Import' } },
            { path: 'data-export', loadChildren: () => import('app/data-export/export.module').then(m => m.ExportModule), data: { pageTitle: 'Data Export' } },
            { path: 'cert-map', loadChildren: () => import('app/cert-map/cert-map.module').then(m => m.CertMapModule), canActivate: [ScopeGuardService], data: { pageTitle: 'CertMap', expectedScopes: ["add-on:cert-map"] } },
            { path: 'cert-book', loadChildren: () => import('app/cert-book/cert-book.module').then(m => m.CertBookModule), canActivate: [ScopeGuardService], data: { pageTitle: 'CertBook', expectedScopes: ["add-on:cert-book"] }},
            { path: 'audit', loadChildren: () => import('app/audit/audit.module').then(m => m.AuditModule), canActivate: [ScopeGuardService], data: { pageTitle: 'Audit', expectedScopes: ["add-on:audit"] } }
        ]
    },

    { path: 'auth/login', loadChildren: () => import('app/+auth/+login/login.module').then(m => m.LoginModule) },
    { path: 'auth/reset-password', loadChildren: () => import('app/+auth/+forgot/forgot.module').then(m => m.ForgotModule) },
    { path: 'callback', component: CallbackComponent },
    { path: '**', redirectTo: '' }
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' });