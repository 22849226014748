import { ApplicationRef, Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { JsonApiService } from "../../core/api/json-api.service";
import { IUserPreferences } from '../../settings/preferences/user-preferences/user-preferences.model';
import { config } from '../smartadmin.config';
import { languages } from './languages.model';
import { MessageService } from '@progress/kendo-angular-l10n';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class I18nService extends MessageService {

  public state;
  public data: {};
  public customLabels;
  public kendoData: {};
  public currentLanguage: any;

  public isLoading: boolean = true;

  constructor(private jsonApiService: JsonApiService,
    private ref: ApplicationRef,
    private http: HttpClient) {

    super();
    this.state = new Subject();

    let userPreferences: IUserPreferences = JSON.parse(localStorage.getItem('userPreferences'));
    this.initLanguage(userPreferences && userPreferences.language ? userPreferences.language : (config.defaultLocale || 'en-US'));
    this.fetch(this.currentLanguage.key)
    this.loadKendoTranslation(this.currentLanguage.key);
  }

  private fetch(locale: any) {    
    this.isLoading = true;
    this.jsonApiService.fetch(`/langs/${locale}.json`)
      .subscribe((data: any) => {
        this.data = data;
        this.state.next(data);
        this.ref.tick()
        this.isLoading = false;
      })

      this.jsonApiService.fetch('/custom-labels/custom-labels.json')
          .subscribe((data: any) => {
            this.customLabels = data;
          })
  }

  private initLanguage(locale: string) {
    let language = languages.find((it) => {
      return it.key == locale
    });
    if (language) {

      this.currentLanguage = language
      this.notify();

    } else {
      this.initLanguage('en-US');
      //throw new Error(`Incorrect locale used for I18nService: ${locale}`);
    }
  }

  setLanguage(language) {
    this.currentLanguage = language;
    this.fetch(language.key)
  }

  subscribe(sub: any, err: any) {
    return this.state.subscribe(sub, err)
  }

  public getTranslation(phrase: string): string {
    
     let userPreferences: IUserPreferences = JSON.parse(localStorage.getItem('userPreferences'));

     if(userPreferences && userPreferences.customLabels){
         for (let x of userPreferences.customLabels) {   
            if(x.keyValues && x.keyValues.some(z=> z.id == phrase)){
                if(x.keyValues.some(z=> z.id == phrase && z.name.trim() != '')){
                    { return x.keyValues.find(x=> x.id == phrase && x.name.trim() != '').name; }
                }
                else if(x.value.trim() != ''){
                    { return x.value };
                }
            }
        }
     }
     
    return this.data && this.data[phrase] ? this.data[phrase] : phrase
  }

  public get(key: string): string {
    if (!this.kendoData) {
      this.kendoData = {};
    }
    return this.kendoData[key];
  }

  private loadKendoTranslation(language: string) {
    if (language) {
      if (language == "en-US") {
        //English don't need translation
        this.kendoData = {}
      }
      else {
        //this.isKendoLoading = true;
        //Get translated file and fill messages array
        this.http.get(this.jsonApiService.getBaseUrl() + config.API_URL + '/langs/kendo-' + language.trim() + ".xlf", { responseType: 'text' })
          .subscribe(xml => this.fillKendoMessages(xml));
      }
    }
  }

  private fillKendoMessages(xml) {
    //Initializing the array of messages. Ensuring that it will be clean.
    this.kendoData = {};
    //Parsing object
    let parseString = require('xml2js').parseString;
    let json;
    //Convert XML to JSON
    parseString(xml, (err, result) => json = result);
    //Splitting the object because property "trans-unit" has a not allowed special caracter (-)  
    let body = json.xliff.file[0].body[0];
    //Getting property trans-unit
    var messages = Object.values(Object.values(body)[0]);
    //Append kendo messages
    messages.forEach((element) => this.kendoData[element.note[1]._] = element.target[0]);
    this.notify();
  }
}
