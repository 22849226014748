import { IdNameValueObject } from "../../../../ignite/id-name-value-object.model";

export class ITask {
    id: string;
    title: string;
    assetList?: IdNameValueObject;
    assetsCurrentCount: number;
    assetsDueCount: number;
    assetsOverDueCount: number;
    location?: IdNameValueObject;
    nextDueDate?: Date;
    oldestDueDate?: Date;
    owner?: IdNameValueObject;
    myselfOnly: boolean;
}