import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ThemesService } from "../../../settings/themes/themes.service";
import { I18nModule } from "../../i18n/i18n.module";
import { UserModule } from "../../user/user.module";
import { UtilsModule } from "../../utils/utils.module";
import { ActivitiesMessageComponent } from "./activities/activities-message/activities-message.component";
import { ActivitiesNotificationComponent } from "./activities/activities-notification/activities-notification.component";
import { ActivitiesTaskComponent } from "./activities/activities-task/activities-task.component";
import { ActivitiesComponent } from "./activities/activities.component";
import { CollapseMenuComponent } from "./collapse-menu/collapse-menu.component";
import { FullScreenComponent } from "./full-screen/full-screen.component";
import { HeaderComponent } from "./header.component";
import { RecentProjectsComponent } from "./recent-projects/recent-projects.component";
import { RouteBreadcrumbsComponent } from "../ribbon/route-breadcrumbs.component";
import { LayoutService } from "../layout.service";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ModalModule } from "ngx-bootstrap/modal";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,    
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    UtilsModule,
    I18nModule,
    UserModule
  ],
  declarations: [
    ActivitiesMessageComponent,
    ActivitiesNotificationComponent,
    ActivitiesTaskComponent,
    RecentProjectsComponent,
    FullScreenComponent,
    CollapseMenuComponent,
    ActivitiesComponent,
    HeaderComponent,
    RouteBreadcrumbsComponent
  ],
  exports: [
    HeaderComponent
  ],
  providers: [ThemesService, LayoutService]
})
export class HeaderModule { }
