import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropDownListComponent as kendoDropdown } from '@progress/kendo-angular-dropdowns';
import { ApplicationContext } from '../application-context';
import { IDropdownList } from './dropdown-list.model';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styles: [
    '.option-group {  font-size: 10px; display: block; position: absolute; top: -1px; right: 0; padding: 0 .5em; line-height: 1.8; background: #999; color: #fff; border-bottom-left-radius: 1px; }',
    '.option-item { display: block; width: 100%; position: relative; }',
    '.option-item-group { border-top: 1px solid #999; }'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownListComponent),
      multi: true,
    }]
})

export class DropdownListComponent implements ControlValueAccessor, OnInit {
  @Input() data: IDropdownList[] = [];
  @Input() filterable: boolean = true;
  @Input() inputClass: string = '';
  @Input() orderByName: boolean = true;
  @Input() loading: boolean = false;
  @Input() hasIconFirst: boolean = false;
  @Input() id: string;
  @Input() defaultItem: IDropdownList = { id: null, name: this.applicationContext.i18nService.getTranslation('Select') + '...' };

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  isDisabled: boolean;
  dropdownData: IDropdownList[] = [];
  selectedItem: string;

  @ViewChild('dropdown', {static: true}) dropdownComponent: kendoDropdown;

  constructor(private applicationContext: ApplicationContext) {
  }

  ngOnInit() {
    this.dropdownData = [];
    if (this.data) {
      if (this.orderByName) {
        this.dropdownData = this.data.sort((n1, n2) => {
          if (n1.name.toLowerCase() > n2.name.toLowerCase()) {
            return 1;
          }
          if (n1.name.toLowerCase() < n2.name.toLowerCase()) {
            return -1;
          }
          return 0;
        });
      }
      else {
        this.dropdownData = this.data;
      }
    }
  }

  filterChange(value) {
    this.dropdownData = this.data.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  selectionChange(event) {
    if (event) {
      this.selectedItem = event;
      this.propagateChange(event);
      this.onChange.emit(event);
    }
    else {
      this.selectedItem = null
      this.propagateChange(null);
      this.onChange.emit(null);
    }

  }

  writeValue(obj: any): void {
    this.selectedItem = obj;
    this.dropdownComponent.writeValue(obj);
    this.ngOnInit();
  }

  private propagateChange = (_: any) => { };

  private propagateBlur = (_: any) => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.propagateBlur = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  open() {
    this.ngOnInit();
  }

}
