

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LoginInfoComponent} from "./login-info/login-info.component";
import {LogoutComponent} from "./logout/logout.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { I18nModule } from "../i18n/i18n.module";

@NgModule({
  imports: [CommonModule,  I18nModule, BsDropdownModule.forRoot(),],
  declarations: [LoginInfoComponent, LogoutComponent],
  exports: [LoginInfoComponent, LogoutComponent]
})
export class UserModule{}
