import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITheme, IReportTemplate } from 'app/settings/themes/theme';
import { ApplicationContext } from 'app/shared/ignite/application-context';
import { Service } from 'app/shared/ignite/service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ThemesService extends Service {
    private baseUrl: string;
    private getAllUrl: string;

    constructor(applicationContext: ApplicationContext,
        private httpClient: HttpClient) {
        super(applicationContext);

        this.baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${this.tenantId}/theme`;
        this.getAllUrl = `${this.applicationContext.configurationService.apiBaseUrl}/theme`;
    }

    public getSupportedThemes(): Observable<string[]> {
        return this.httpClient.get<string[]>(this.getAllUrl)
            .pipe(catchError(this.handleError([])));
    }

    public getCurrent(tenantId: string = this.tenantId): Observable<ITheme> {
        let url = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/theme`;
        return this.httpClient.get<ITheme>(url)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public getCurrentNoCache(tenantId: string = this.tenantId): Observable<ITheme> {
        let url = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/theme/noCache`;        
        return this.httpClient.get<ITheme>(url)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    public save(theme: ITheme, tenantId: string = this.tenantId): Observable<ITheme> {
        let url = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/theme`;

        return this.httpClient.put<ITheme>(url, theme, this.httpOptions)
            .pipe(catchError(this.handleError(this.createEmpty())));
    }

    private createEmpty(): ITheme {

        const themeReportEmpty: IReportTemplate[] = [{
            id: null,
            nameCustomReport: null,
            defaultTemplate: null,
            reportHeaderLogo: {
                contentType: null, effectiveDate: null, internalName: null, name: null
            },
            reportFooterLogo: {
                contentType: null, effectiveDate: null, internalName: null, name: null
            },
        }];

        return {
            name: null,
            bannerLogo: { contentType: null, effectiveDate: null, internalName: null, name: null },
            reportTemplate: themeReportEmpty
        };
    }
}
