import { Injectable } from "@angular/core";
import { ApplicationContext } from "app/shared/ignite/application-context";
import { Service } from "app/shared/ignite/service";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Injectable()

export class SynchronizationService extends Service {    
    private baseUrl: string;

    constructor(applicationContext: ApplicationContext, 
        private httpClient: HttpClient) {
        super(applicationContext);  

        this.baseUrl = `${applicationContext.configurationService.apiBaseUrl}/synchronization/`;
    }    

    public syncInitiliaze(tenantId: string): Observable<any> {
        let baseUrl = `${this.applicationContext.configurationService.apiBaseUrl}/synchronization/tenant/${tenantId}/initialize`;

        return  this.httpClient.post<any>(baseUrl, null, this.httpOptions)
            .pipe(catchError(this.handleError(null)));
        
    }


}