import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SmartadminModule } from "app/shared/smartadmin.module";
import { DownloadComponent } from "app/download/download.component";
import { NgModule } from "@angular/core";
import { IntlModule } from "@progress/kendo-angular-intl";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SmartadminModule,
        IntlModule
    ],
    declarations: [
        DownloadComponent
    ]
})
export class DownloadModule { }