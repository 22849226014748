import { NgModule } from "@angular/core";

import { routing } from "./asset-management.routing";

@NgModule({
    declarations: [
    ],
    exports: [
    ],
    imports: [
        routing,
    ]
})
export class AssetManagementModule {
}