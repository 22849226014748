import { OnInit, ViewChild, AfterViewInit, OnDestroy, Directive, EventEmitter, Output, HostListener } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { ColumnBase, ColumnResizeArgs, DataStateChangeEvent, GridComponent, GridDataResult, PageChangeEvent, SelectAllCheckboxState } from "@progress/kendo-angular-grid";
import { process, State, FilterDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { defineFont } from "@progress/kendo-drawing/pdf";
import { IUserPreferences } from "app/settings/preferences/user-preferences/user-preferences.model";
import { Permission } from "app/settings/users/permission";
import { ApplicationContext } from "app/shared/ignite/application-context";
import { BaseComponent } from "./base.component";
import { ISearchCriteria } from "./search-criteria";
import { ExcelExportEvent } from "@progress/kendo-angular-grid/dist/es2015/excel/excel-export-event";
import { IGridConfigurations } from "app/settings/grid-configurations/grid-configurations.model";
import { ListStateComponent } from "./list-state/list-state.component";
import { IListState, ListType } from "./list-state/list-state.model";
import { Subscription } from "rxjs";

@Directive()
export abstract class ListComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    public tenantId: string;
    public hasHeadOfficeAddOn: boolean;

    protected serverPageSize?: number;
    protected userPreferencesMaxFetchedRecords: number;
    protected sessionId: string;
    private request: Subscription;

    protected defaultHiddenColumns: string[] = [];
    protected get hiddenColumns(): string[] {
        return this.gridConfigurations?.columnsConfiguration.filter(n => n.isHidden).map(n => n.title) ?? null;
    };

    public totalCount: number = null;
    public totalCountLoading: boolean = false;
    public showSearchToolbar: boolean = false;
    public isLoadingGrid: boolean = false;

    breadcrumbs: string[];

    formGroup: FormGroup;
    protected searchCriteria: ISearchCriteria;

    public columns: string[] = [];

    public listType: ListType = ListType.Asset;
    public selectedSavedSearch: IListState;

    @Output() onFinishLoadGridConfigurationHandler: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('listStateComponent') listStateComponent: ListStateComponent;
    @ViewChild('grid') grid: GridComponent;

    private _data: any[];
    public selectedIndexes: string[] = [];
    public selectAllState: SelectAllCheckboxState = 'unchecked';

    get data(): any[] {
        return this._data;
    }

    set data(data: any[]) {
        this._data = data;
        if (this.state.skip >= data.length) {
            this.state.skip = 0;
        }

        this.gridData = process(this.data, this.state);
    }

    protected buttonCount = 5;
    protected info = true;
    protected type: 'numeric' | 'input' = 'numeric';
    protected pageSizes = true;
    protected previousNext = true;
    protected pageSize = 50;
    protected skip = 0;
    pageable: any;

    protected isServerPagination: boolean = false;

    private _state: State = { skip: 0, take: this.pageSize };

    public get state(): State { return  this.gridConfigurations?.state || this._state; }

    public set state(value : State) {
        if (this.gridConfigurations)
            this.gridConfigurations.state = value;
        else
            this._state = value;
    }

    private _gridConfigurations: IGridConfigurations;

    public get gridConfigurations(): IGridConfigurations { 
        
        if (this._gridConfigurations) {
            let dateColumns: string[] = ['currentRevision.currentStatusDate', 'currentStatusDate',
                'creationDate', 'nextDueDate', 'completedDate', 'modifiedDate', 'dateOfManufacture', 'completedDate',
                'lastRun', 'assetSchedule.dueDate', 'startDate', 'endDate', 'performedDate'];

            // Fixed date return from cache
            if (this._gridConfigurations.state && this._gridConfigurations.state.filter && this._gridConfigurations.state.filter.filters) { 
                this._gridConfigurations.state.filter.filters.forEach((compositeFilter: CompositeFilterDescriptor) => {
                    compositeFilter.filters.filter((n: FilterDescriptor) => dateColumns.some(x => x === n.field) && n.value)
                        .forEach((n: FilterDescriptor) => {
                            n.value = new Date(n.value);
                        })
                });
            }

        }    

        return this._gridConfigurations;
    }

    public set gridConfigurations(value : IGridConfigurations) {
        this._gridConfigurations = value;
    }
    
    get searchCriteriaItems(): FormArray { return <FormArray>this.formGroup.controls.searchCriteriaItems; }

    gridData: GridDataResult;

    constructor(applicationContext: ApplicationContext,
        protected activatedRoute: ActivatedRoute,
        protected formBuilder: FormBuilder,
        protected sessionIdentifier: string,
        protected persistConfigurationDisabled: Boolean = false) {
        super(applicationContext, activatedRoute, formBuilder);

        this.sessionId = sessionIdentifier;
        this.hasHeadOfficeAddOn = this.checkPermission(Permission.AddonHeadOffice);
        let gridConfiguration = JSON.parse(sessionStorage.getItem('gridConfiguration-' + this.sessionId));
        if (gridConfiguration)
            this.gridConfigurations = gridConfiguration;
        this.searchCriteria = JSON.parse(sessionStorage.getItem('searchCriteria-' + this.sessionId));

        this.pageable = {
            buttonCount: this.buttonCount,
            info: this.info,
            type: this.type,
            pageSizes: this.pageSizes,
            previousNext: this.previousNext
        };
        this.allData = this.allData.bind(this);

        let userPrefs: IUserPreferences = JSON.parse(localStorage.getItem('userPreferences'));
        this.userPreferencesMaxFetchedRecords = userPrefs && userPrefs.maximumFetchedRecords ? userPrefs.maximumFetchedRecords : 1000;
        this.serverPageSize = this.userPreferencesMaxFetchedRecords;

    }

    // @HostListener('window:beforeunload', ['$event'])
    // unload($event: any) {

    //     if (this.auth0Service.loggedIn) {
    //         this.saveColumnsConfiguration();
    //     }
    // }

    ngOnDestroy(): void {
        // this.saveColumnsConfiguration();
    }

    ngAfterViewInit(): void {

        if (this.grid) {
            this.grid.resizable = true;
            this.grid.columnReorder.subscribe((n: any) => {
                if (!this.isLoadingGrid) {
                    setTimeout(() => this.saveColumnsConfiguration(), 500);
                }
            });
            this.grid.columnResize.subscribe((n: any) => {
                if (!this.isLoadingGrid) {
                    this.saveColumnsConfiguration();
                }
            });
        }

        if (this.gridConfigurations) {
            this.applyColumnsConfiguration();
            return;
        }

        //load from database
        let nameGrid = this.sessionId;
        if (nameGrid != null && nameGrid != '') {
            this.applicationContext.gridConfigurationService.getById(this.currentUser.id, nameGrid, this.currentUser.tenantId).subscribe(grid => {                
                this.gridConfigurations = grid;
                if (this.gridConfigurations != null) {
                    if (!this.gridConfigurations.columnsConfiguration)
                        this.gridConfigurations.columnsConfiguration = [];
                    sessionStorage.setItem('gridConfiguration-' + this.sessionId, JSON.stringify(this.gridConfigurations));
                }
                else {
                    this.gridConfigurations = this.applicationContext.gridConfigurationService.createEmpty();
                    this.gridConfigurations.name = this.sessionId;
                    this.gridConfigurations.tenantId = this.currentUser.tenantId;
                    this.gridConfigurations.userId = this.currentUser.id;
                }

                this.applyColumnsConfiguration();
            },
                error => {
                    this.hideLoading();
                    this.alertService.error("An error occurred while listing the grid columns");
                });
        }

    }

    _search(): void { }

    public loadListPage(): void {
        this.showSearchToolbar = true;

        let optOutFilter = sessionStorage.getItem("searchCriteria-optOut" + this.sessionId) == 'true';
        if (optOutFilter === true) {
            this.formGroup.controls['includeOptOut'].setValue(true);
        }

        if ((this.searchCriteria && this.searchCriteria.searchCriteriaItems && this.searchCriteria.searchCriteriaItems.length > 0) || optOutFilter === true) {
            this.formGroup.patchValue(this.searchCriteria);
            this.formGroup.markAsDirty();
            this.search()
        }
        else if (sessionStorage.getItem('searchCriteria-tenantId-' + this.sessionId)) {
            this.search();
        }
        else {
            this.data = [];
            this.hideLoading();
        }
    }

    pageChange({ skip, take }: PageChangeEvent): void {
        this.skip = skip;
        this.pageSize = take;
        this.gridData = process(this.data, this.state);
    }

    dataStateChange(state: DataStateChangeEvent): void {
        this.gridConfigurations.state = state;
        this.saveGridConfigurationOnCache();
        this.gridData = process(this.data, this.state);
    }

    protected saveColumnsConfiguration(): void {
        if (this.grid) {
            this.gridConfigurations.columnsConfiguration = [];
            this.grid.columnList.toArray().sort((n1, n2) => {
                if (n1.orderIndex > n2.orderIndex) {
                    return 1;
                }
                if (n1.orderIndex < n2.orderIndex) {
                    return -1;
                }
                return 0;
            }).forEach(n => {
                this.gridConfigurations.columnsConfiguration.push({ title: n.title, width: (n.width != null && n.width < 15) ? 15 : n.width, orderIndex: n.orderIndex, isHidden: n.hidden });
            });

            this.saveGridConfigurationOnCache();
            this.persistGridConfigurations();
        }
    }

    public applyColumnsConfiguration() {
        if (this.grid) {
            this.isLoadingGrid = true;
            let columns: { index: number, isHidden: boolean, column: ColumnBase }[] = [];
            this.grid.columnList.toArray().forEach((column, i) => {
                let config = this.gridConfigurations.columnsConfiguration?.find(n => n.title == column.title);
                                                
                column.hidden = config ? config.isHidden : this.defaultHiddenColumns.some(n => n === column.title);

                if (!config)
                    return;
                if (column.title !== 'Actions')    
                    column.width = (config.width != null && config.width < 15) ? 15 : config.width;
                columns.push({ index: config.orderIndex, isHidden: config.isHidden, column });
            });

            columns.filter(n => !n.isHidden).sort((n1, n2) => {
                if (n1.index > n2.index)
                    return 1;
                if (n1.index < n2.index)
                    return -1;

                return 0;
            }).forEach((n, i) => {
                this.grid.reorderColumn(n.column, i);
            });

            this.isLoadingGrid = false;

            this.saveColumnsConfiguration();
            this.onFinishLoadGridConfigurationHandler.next(true);
        }
    }

    public setGridDataValue(fieldName: string, itemId: string, itemValue: any) {
        var item = this.gridData.data.find(x => x.id == itemId);

        if (item) {
            item[fieldName] = itemValue;
        }
    }

    protected exportToExcel(grid: GridComponent): void {
        if (grid) {
            grid.saveAsExcel();
        }
    }

    public onExcelExport(e: ExcelExportEvent): void { }

    protected exportToPDF(grid: GridComponent): void {
        defineFont({
            'WebComponentsIcons': 'assets/WebComponentsIconsPDF.ttf' //"https://kendo.cdn.telerik.com/2017.1.223/styles/fonts/glyphs/WebComponentsIcons.ttf"
        });

        grid.header = this.applicationContext.currentReportHeaderLogo;
        grid.footer = this.applicationContext.currentReportHeaderLogo;
      
        grid?.saveAsPDF();
    }

    public toggleSearchToolbar() {
        this.showSearchToolbar = !this.showSearchToolbar;
    }

    public clear(): void {
        ((<FormArray>this.formGroup.controls.searchCriteriaItems).controls).forEach(x =>
            x.patchValue({
                value1: null,
                value2: null
            }));

        if (this.formGroup.controls['includeOptOut'])
            this.formGroup.controls['includeOptOut'].setValue(false);

        sessionStorage.removeItem("searchCriteria-" + this.sessionId);
        sessionStorage.removeItem("searchCriteria-tenantId-" + this.sessionId);
        sessionStorage.removeItem("searchCriteria-tenantId-" + this.sessionId);
        sessionStorage.removeItem('listState-' + this.listType);
        sessionStorage.removeItem("searchCriteria-optOut" + this.sessionId);

        this.state = { skip: 0, take: this.pageSize, filter: null };

        this.applyColumnsConfiguration();

        this.data = [];
        this.gridData = process(this.data, this.state);
        this.selectAllState = 'unchecked';
        this.selectedIndexes = [];
        this.showSearchToolbar = true;
        this.totalCount = null;
    }

    protected abstract delete(id: string): void;

    protected abstract search(): void;

    public allData(): ExcelExportData {
        const result: ExcelExportData = {
            data: this.state ? process(this.data, { sort: this.state.sort, filter: this.state.filter }).data : this.data,
        };

        return result;
    }  
   
    public getGridColumns(grid: GridComponent) {
        if (grid) {
            if (this.columns.length === 0)
                this.columns = grid.columnList.filter(n => n.title !== '#' && n.title !== 'Actions' && n.title !== undefined && n.title !== '' && n.title !== 'Tenant')
                    .map(n => n.title)
                    //.concat(this.hiddenColumns)
                    .sort((n1, n2) => {
                        if (n1.toLowerCase().trim() > n2.toLowerCase().trim()) {
                            return 1;
                        }
                        if (n1.toLowerCase().trim() < n2.toLowerCase().trim()) {
                            return -1;
                        }
                        return 0;
                    })
        }

        //this.columnOrder = grid.columnList.toArray().map(n => n.title);
    }

    isHidden(columnName: string): boolean {
        if (this.gridConfigurations?.columnsConfiguration && this.gridConfigurations.columnsConfiguration.length > 0)
            return this.gridConfigurations.columnsConfiguration?.findIndex(n => n.title === columnName && n.isHidden) > -1;
        else
            return this.defaultHiddenColumns.findIndex(n => n === columnName) > -1;
    }

    keyDownFunction(event: any) {
        if (event.keyCode == 13) {
            //this._search()
            return true;
        }
    }

    hideColumn(columnName: string): void {

        if (this.grid) {
            this.grid.columnList.forEach((column: ColumnBase) => {
                if (column.title === this.applicationContext.i18nService.getTranslation(columnName)) {
                    column.hidden = !column.hidden;
                    this.grid.reorderColumn(column, this.grid.columnList.filter(n => !n.hidden).length)
                    return;
                }
            });
            this.saveColumnsConfiguration();
        }
    }

    public onSelectedKeysChange(e: any) {
        const len = this.selectedIndexes.length;

        if (len === 0) {
            this.selectAllState = 'unchecked';
        } else if (len > 0 && len < this.data.length) {
            this.selectAllState = 'indeterminate';
        } else {
            this.selectAllState = 'checked';
        }
    }

    public onSelectAllChange(checkedState: SelectAllCheckboxState, idProperty: string = "id") {
        if (checkedState === 'checked') {
            let filteredData: any[] = process(this.data, { filter: this.state.filter }).data;

            this.selectedIndexes = filteredData.map((item) => item[idProperty]);
            this.selectAllState = 'checked';
        } else {
            this.selectedIndexes = [];
            this.selectAllState = 'unchecked';
        }
    }

    private persistGridConfigurations(): void {
        //do not persist on default view when using saved search
        if (this.listStateComponent?.selectedValue || this.selectedSavedSearch)
            return;

        if (this.sessionId != '' && this.currentUser) {

            if (this.gridConfigurations == null) {
                this.gridConfigurations = this.applicationContext.gridConfigurationService.createEmpty();
                this.gridConfigurations.name = this.sessionId;
                this.gridConfigurations.tenantId = this.currentUser.tenantId;
                this.gridConfigurations.userId = this.currentUser.id;
            }

            if (!this.persistConfigurationDisabled) {

                if (this.request && !this.request.closed)
                    this.request.unsubscribe();

                this.request = this.applicationContext.gridConfigurationService.save(this.gridConfigurations, this.currentUser.tenantId).subscribe(
                    resultGridConfigurations => {
                        this.gridConfigurations = resultGridConfigurations;
                        this.saveGridConfigurationOnCache();
                    },
                    error => {
                        this.hideLoading();
                        this.alertService.error("An error occurred while saving the grid columns");
                    }
                );

            }
            else {
                this.saveGridConfigurationOnCache();
            }
        }
    }

    private saveGridConfigurationOnCache(): void {
        if (!this.listStateComponent?.selectedValue)
            sessionStorage.setItem('gridConfiguration-' + this.sessionId, JSON.stringify(this.gridConfigurations));
    }

    public onReorder(e, grid: GridComponent) {
        
        let fixedIndexes = grid.columnList.filter(n => n.reorderable === false).map(n => n.orderIndex);

        if (fixedIndexes.some(n => n == e.newIndex) || fixedIndexes.some(n => n == e.oldIndex) ) {
            e.preventDefault();
        }
    }
}