export enum Permission {
    AddonAlertAndNotification = "add-on:alert-notification",
    AddonAnalytics = "add-on:analytics",
    AddonAPI = "add-on:API",
    AddonAssetManagement = "add-on:asset-management",
    AddonAudit = "add-on:audit",
    AddonCertBook = "add-on:cert-book",
    AddonCertMap = "add-on:cert-map",
    AddonCertPlan = "add-on:cert-plan",
    AddonDocumentManager = "add-on:document-management",
    AddonEcosystem = "add-on:ecosystem",
    AddonFracLog = "add-on:frac-log",
    AddonHeadOffice = "add-on:head-office",
    AddonHoseSTSTimer = "add-on:hose-sts-timer",
    AddonLocationAndStatusManagement = "add-on:location-and-status-management",
    AddonSafety = "add-on:safety",
    AddonShareLicenses = "add-on:share-licenses",
    AddonTestbench = "add-on:testbench",
    AdminFracLog = "admin-frac-log",
    Administrator = "admin:infochip",
    ApproveDocument = "approve:document",
    ApproveForm = "approve:form",
    AssignContact = "assign:contact",
    AssignCustomer = "assign:customer",
    AssignLicense = "assign:license",
    BlockUser = "block:user",
    BrowseAsset = "browse:asset",
    BrowseAssetManagement = "browse:asset-management",
    BrowseAssetTemplate = "browse:asset-template",
    BrowseAttachmentLabel = "browse:attachment-label",
    BrowseAttribute = "browse:attribute",
    BrowseCategory = "browse:category",
    BrowseContact = "browse:contact",
    BrowseCustomer = "browse:customer",
    BrowseCustomStatus = "browse:custom-status",
    BrowseDashboard = "browse:dashboard",
    BrowseDesktopApp = "browse:desktop-app",
    BrowseDocument = "browse:document",
    BrowseEcoCircle = "browse:eco-circle",
    BrowseFixedReader = "browse:fixed-reader",
    BrowseForm = "browse:form",
    BrowseFormTemplate = "browse:form-template",
    BrowseFormType = "browse:form-type",
    BrowseHoseString = "browse:hose-string",
    BrowseLicense = "browse:license",
    BrowseLocation = "browse:location",
    BrowseManufacturer = "browse:manufacturer",
    BrowseMobileApp = "browse:mobile-app",
    BrowseMyJob = "browse:my-job",
    BrowseMyJobTask = "browse:my-job-task",
    BrowsePallet = "browse:pallet",
    BrowsePreviousDocumentRevision = "browse:previous-document-revision",
    BrowseReorder = "browse:reorder",
    BrowseReport = "browse:report",
    BrowseRole = "browse:role",
    BrowseScheduledExport = "browse:scheduled-export",
    BrowseScheduledReport = "browse:scheduled-report",
    BrowseTag = "browse:tag",
    BrowseTenant = "browse:tenant",
    BrowseTenantPreferences = "browse:tenant-preferences",
    BrowseTheme = "browse:theme",
    BrowseUser = "browse:user",
    BrowseUserGroups = "browse:user-groups",
    BrowseWebApp = "browse:web-app",
    BrowseWidgetFailedAssetsByAssetTemplate = "browse:widget-failed-assets-by-asset-template",
    BrowseWidgetFailedAssetsByAssetTemplateAndLocation = "browse:widget-failed-assets-by-asset-template-and-location",
    BrowseWorkflow = "browse:workflow",
    BrowseWorkflowTemplate = "browse:workflow-template",
    BrowseWorkOrder = "browse:work-order",
    BulkUTIForm = "bulk:uti-form",
    CheckInAsset = "check-in:asset",
    CheckOutAsset = "check-out:asset",
    CompleteForm = "complete:form",
    CreateAsset = "create:asset",
    CreateAssetTemplate = "create:asset-template",
    CreateAttachmentLabel = "create:attachment-label",
    CreateAttribute = "create:attribute",
    CreateCategory = "create:category",
    CreateContact = "create:contact",
    CreateCustomer = "create:customer",
    CreateCustomStatus = "create:custom-status",
    CreateDocument = "create:document",
    CreateDocumentRevision = "create:document-revision",
    CreateEcoCircle = "create:eco-circle",
    CreateFixedReader = "create:fixed-reader",
    CreateForm = "create:form",
    CreateFormTemplate = "create:form-template",
    CreateFormType = "create:form-type",
    CreateGlobalAssetTemplate = "create:global-asset-template",
    CreateGlobalAttachmentLabel = "create:global-attachment-label",
    CreateGlobalCategory = "create:global-category",
    CreateGlobalFormTemplate = "create:global-form-template",
    CreateGlobalFormType = "create:global-form-type",
    CreateGlobalLicense = "create:global-license",
    CreateGlobalWorkflowTemplate = "create:global-workflow-template",
    CreateLicense = "create:license",
    CreateLocation = "create:location",
    CreateManufacturer = "create:manufacturer",
    CreateMyJob = "create:my-job",
    CreateMyJobTask = "create:my-job-task",
    CreatePallet = "create:pallet",
    CreateReorder = "create:reorder",
    CreateRole = "create:role",
    CreateScheduledExport = "create:scheduled-export",
    CreateScheduledReport = "create:scheduled-report",
    CreateTag = "create:tag",
    CreateTenant = "create:tenant",
    CreateTheme = "create:theme",
    CreateUser = "create:user",
    CreateUserGroups = "create:user-groups",
    CreateWorkflow = "create:workflow",
    CreateWorkflowTemplate = "create:workflow-template",
    CreateWorkOrder = "create:work-order",
    DeleteAsset = "delete:asset",

    DeleteAssetTemplate = "delete:asset-template",
    DeleteAttachmentLabel = "delete:attachment-label",
    DeleteAttribute = "delete:attribute",
    DeleteCategory = "delete:category",
    DeleteCompletedForm = "delete:completed-form",
    DeleteContact = "delete:contact",
    DeleteCustomer = "delete:customer",
    DeleteCustomStatus = "delete:custom-status",
    DeleteDocument = "delete:document",
    DeleteEcoCircle = "delete:eco-circle",
    DeleteFixedReader = "delete:fixed-reader",
    DeleteForm = "delete:form",

    DeleteFormTemplate = "delete:form-template",
    DeleteFormType = "delete:form-type",
    DeleteGlobalAssetTemplate = "delete:global-asset-template",
    DeleteGlobalAttachmentLabel = "delete:global-attachment-label",
    DeleteGlobalCategory = "delete:global-category",
    DeleteGlobalFormTemplate = "delete:global-form-template",
    DeleteGlobalFormType = "delete:global-form-type",
    DeleteGlobalLicense = "delete:global-license",
    DeleteGlobalWorkflowTemplate = "delete:global-workflow-template",
    DeleteJob = "delete:job",
    DeleteLicense = "delete:license",
    DeleteLocation = "delete:location",
    DeleteManufacturer = "delete:manufacturer",
    DeleteMyJob = "delete:my-job",
    DeleteMyJobTask = "delete:my-job-task",
    DeletePallet = "delete:pallet",
    DeleteReorder = "delete:reorder",
    DeleteRole = "delete:role",
    DeleteScheduledExport = "delete:scheduled-export",
    DeleteScheduledReport = "delete:scheduled-report",
    DeleteTag = "delete:tag",
    DeleteTenant = "delete:tenant",
    DeleteTheme = "delete:theme",
    DeleteUser = "delete:user",
    DeleteUserGroups = "delete:user-groups",
    DeleteWorkflow = "delete:workflow",
    DeleteWorkflowTemplate = "delete:workflow-template",
    DeleteWorkOrder = "delete:work-order",
    DistributeAssetTemplate = "distribute:asset-template",
    DistributeAttachmentLabel = "distribute:attachment-label",
    DistributeCategory = "distribute:category",
    DistributeContact = "distribute:contact",
    DistributeFormTemplate = "distribute:form-template",
    DistributeFormType = "distribute:form-type",
    DistributeLicense = "distribute:license",
    DistributeWorkflowTemplate = "distribute:workflow-template",
    DownloadPreviousDocumentRevision = "download:previous-document-revision",
    ExecuteAdminTask = "execute:admin-task",
    ExecuteImpersonate = "execute:impersonate",
    ExportAsset = "export:asset",
    ExportAssetAttachment = "export:asset-attachment",
    ExportAssetTemplate = "export:asset-template",
    ExportAttribute = "export:attribute",
    ExportCustomer = "export:customer",
    ExportForm = "export:form",
    ExportFormTemplate = "export:form-template",
    ExportLocation = "export:location",
    ImportAsset = "import:asset",
    ImportAssetAttachment = "import:asset-attachment",
    ImportAssetTemplate = "import:asset-template",
    ImportAttribute = "import:attribute",
    ImportCustomer = "import:customer",
    ImportForm = "import:form",
    ImportFormTemplate = "import:form-template",
    ImportLocation = "import:location",
    ImportUser = "import:user",
    MassActionDeleteAsset = "mass-action-delete:asset",
    MassActionDeleteForm = "mass-action-delete:form",
    openid = "openid",
    optInAttribute = "opt-in:attribute",
    optInManufacturer = "opt-in:manufacturer",
    optInTag = "opt-in:tag",
    optOutAttribute = "opt-out:attribute",
    optOutManufacturer = "opt-out:manufacturer",
    optOutTag = "opt-out:tag",
    profile = "profile",
    PublishDocument = "publish:document",
    ReadAsset = "read:asset",
    ReadAssetTemplate = "read:asset-template",
    ReadAttachmentLabel = "read:attachment-label",
    ReadAttribute = "read:attribute",
    ReadCategory = "read:category",
    ReadContact = "read:contact",
    ReadCustomer = "read:customer",
    ReadCustomStatus = "read:custom-status",
    ReadDocument = "read:document",
    ReadEcoCircle = "read:eco-circle",
    ReadFixedReader = "read:fixed-reader",
    ReadForm = "read:form",
    ReadFormTemplate = "read:form-template",
    ReadFormType = "read:form-type",
    ReadLicense = "read:license",
    ReadLocation = "read:location",
    ReadManufacturer = "read:manufacturer",
    ReadMyJob = "read:my-job",
    ReadMyJobTask = "read:my-job-task",
    ReadPreviousDocumentRevision = "read:previous-document-revision",
    ReadReorder = "read:reorder",
    ReadRole = "read:role",
    ReadScheduledExport = "read:scheduled-export",
    ReadScheduledReport = "read:scheduled-report",
    ReadTag = "read:tag",
    ReadTenant = "read:tenant",
    ReadTheme = "read:theme",
    ReadUser = "read:user",
    ReadWorkflow = "read:workflow",
    ReadWorkflowTemplate = "read:workflow-template",
    RejectDocument = "reject:document",
    RejectForm = "reject:form",
    ReportActivity = "report:activity",
    ReportAssetCheckOutInHistory = "report:asset-check-out-in-history",
    ReportChippedSlings = "report:chipped-slings",
    ReportCompletedOpsFormsDetails = "report:completed-ops-forms-details",
    ReportCptIronCertification = "report:cpt-iron-certification",
    ReportCriticalLoadPath = "report:critical-load-path",
    ReportCrownBlock = "report:crown-block",
    ReportDeadlineAnchorAuxiliary = "report:deadline-anchor-auxiliary",
    ReportDeadlineAnchorMain = "report:deadline-anchor-main",
    ReportDrops = "report:drops",
    ReportDuplicatedAssetNumbers = "report:duplicated-asset-numbers",
    ReportDyePenetrantInspection = "report:dye-penetrant-inspection",
    ReportElevators = "report:elevators",
    ReportIronCertification = "report:iron-certification",
    ReportKNCNLolerExamination = "report:kncn-loler-examination",
    ReportLiftingRigging = "report:lifting-rigging",
    ReportLoler = "report:loler",
    ReportLOSCrewPickup = "report:los-crew-pickup",
    ReportLOSFailedIron = "report:los-failed-iron",
    ReportLOSInventoryProgress = "report:los-inventory-progress",
    ReportMagneticParticleInspection = "report:magnetic-particle-inspection",
    ReportMultiColumnCert = "report:multi-column-cert",
    ReportMyJobSymmary = "report:my-job-summary",
    ReportPCParentChildExamination = "report:pc-parent-child-examination",
    ReportPowerRotatingHookAdapters = "report:power-rotating-hook-adapters",
    ReportRedToGreen = "report:red-to-green",
    ReportRotaryTable = "report:rotary-table",
    ReportSlips = "report:slips",
    ReportSummarized = "report:summarized",
    ReportDescriptionQuantitySummary = "report:description-quantity-summary",
    ReportTenantBilling = "report:tenant-billing",
    ReportTopDrive = "report:top-drive",
    ReportTravelingBlockAuxiliary = "report:traveling-block-auxiliary",
    ReportTravelingBlockMain = "report:traveling-block-main",
    ReportWallThicknessSurvey = "report:wall-thickness-survey",
    ReportWOValveSurvey = "report:wo-valve-survey",
    ReportIncomingEquipment = "report:incoming-equipment",
    RevokeLicense = "revoke:license",
    ShareLicenses = "share:licenses",
    SubmitDocument = "submit:document",
    UnassignContact = "unassign:contact",
    UnassignCustomer = "unassign:customer",
    UndeleteAsset = "undelete:asset",
    UndeleteForm = "undelete:form",
    UpdateAsset = "update:asset",
    UpdateAssetExpirationDate = "update:asset-expiration-date",
    UpdateAssetInServiceDate = "update:asset-in-service-date",
    UpdateAssetLocation = "update:asset-location",
    UpdateAssetManufacturerDate = "update:asset-date-of-manufacture",
    UpdateAssetMinMax = "update:asset-min-max",
    UpdateAssetStatus = "update:asset-status",
    UpdateAssetTemplate = "update:asset-template",
    UpdateAssetTemplateMinMax = "update:asset-template-min-max",
    UpdateAssetRFIDRecycle = "update:asset-rfid-recycle",
    UpdateAttachmentLabel = "update:attachment-label",
    UpdateAttribute = "update:attribute",
    UpdateCategory = "update:category",
    UpdateCompletedForm = "update:completed-form",
    UpdateContact = "update:contact",
    UpdateCustomer = "update:customer",
    UpdateCustomStatus = "update:custom-status",
    UpdateDocument = "update:document",
    UpdateEcoCircle = "update:eco-circle",
    UpdateFixedReader = "update:fixed-reader",
    UpdateForm = "update:form",
    UpdateFormTemplate = "update:form-template",
    UpdateFormType = "update:form-type",
    UpdateGlobalAssetTemplate = "update:global-asset-template",
    UpdateGlobalAttachmentLabel = "update:global-attachment-label",
    UpdateGlobalCategory = "update:global-category",
    UpdateGlobalFormTemplate = "update:global-form-template",
    UpdateGlobalFormType = "update:global-form-type",
    UpdateGlobalWorkflowTemplate = "update:global-workflow-template",
    UpdateLicense = "update:license",
    UpdateLocation = "update:location",
    UpdateManufacturer = "update:manufacturer",
    UpdateMyJob = "update:my-job",
    UpdateMyJobTask = "update:my-job-task",
    UpdatePallet = "update:pallet",
    UpdateReorder = "update:reorder",
    UpdateRole = "update:role",
    UpdateScheduledExport = "update:scheduled-export",
    UpdateScheduledReport = "update:scheduled-report",
    UpdateTag = "update:tag",
    UpdateTenant = "update:tenant",
    UpdateTenantBusiness = "update:tenant-business",
    UpdateTenantPreferences = "update:tenant-preferences",
    UpdateTheme = "update:theme",
    UpdateUser = "update:user",
    UpdateUserGroups = "update:user-groups",
    UpdateWorkflow = "update:workflow",
    UpdateWorkflowTemplate = "update:workflow-template",
    UpdateWorkOrder = "update:work-order",
}