import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ConfigurationService {

    private _configuration: any;

    constructor(private httpClient: HttpClient) {
    }

    public load(): Promise<any> {
        return this.httpClient.get("assets/appsettings.json")
            .toPromise()
            .then(settings => this._configuration = settings);
    }

    public get configuration(): any {
        return this._configuration;
    }    

    public get showAgreementMessage(): string {
        if (this._configuration) {
            return this._configuration.showAgreementMessage;
        }
    }

    public get apiBaseUrl(): string {
        if (this._configuration) {
            return this._configuration.apiBaseUrl;
        }
    }    

    public get clientID(): string {
        if (this._configuration) {
            return this._configuration.clientID;
        }
    }

    public get domain(): string {
        if (this._configuration) {
            return this._configuration.domain;
        }
    }

    public get callbackURL(): string {
        if (this._configuration) {
            return this._configuration.callbackURL;
        }
    }

    public get silentCallbackURL(): string {
        if (this._configuration) {
            return this._configuration.silentCallbackURL;
        }
    }

    public get audience(): string {
        if (this._configuration) {
            return this._configuration.audience;
        }
    }

    public get scope(): string {
        if (this._configuration) {
            return this._configuration.scope;
        }
    }

    public get appMetadataNamespace(): string {
        if (this._configuration) {
            return this._configuration.appMetadataNamespace;
        }
    }

    public get userMetadataNamespace(): string {
        if (this._configuration) {
            return this._configuration.userMetadataNamespace;
        }
    }

    public get desktopAppUrl(): string {
        if (this._configuration) {
            return this._configuration.desktopAppUrl;
        }
    }

    public get testbenchAppUrl(): string {
        if (this._configuration) {
            return this._configuration.testbenchAppUrl;
        }
    }

    public get netFrameworkPrerequisitesUrl(): string {
        if (this._configuration) {
            return this._configuration.netFrameworkPrerequisitesUrl;
        }
    }

    public get dateFormat(): string {
        if (this._configuration) {
            return this._configuration.dateFormat;
        }
    }

    public get dateTimeFormat(): string {
        if (this._configuration) {
            return this._configuration.dateTimeFormat;
        }
    }

    public get automaticUpdateTasks(): boolean {
        if (this._configuration) {
            return this._configuration.automaticUpdateTasks;
        }
    }

    public get taskIntervalTime(): number {
        if (this._configuration) {
            return this._configuration.taskIntervalTime;
        }
    }

    public get maxAllowedLicenseIPs(): number {
        if (this._configuration) {
            return this._configuration.maxAllowedLicenseIPs;
        }
    }

    public get authTokenRenewal(): boolean {
        if (this._configuration) {
            return this._configuration.authTokenRenewal;
        }
    }    

    public get mapSubscriptionKey(): string{
        if (this._configuration) {
            return this._configuration.mapSubscriptionKey;
        }
    }

    public get isGovEnvironment(): string{
        if (this._configuration) {
            return this._configuration.isGovEnvironment || "false";
        }
    }
    
    public get alternativeLoginUrl(): string{
        if (this._configuration) {
            return this._configuration.alternativeLoginUrl;
        }
    }

    public get jobListRefreshTime(): number {
        if (this._configuration) {
            return this._configuration.jobListRefreshTime;
        }
    }

    public get certNetOneBaseUrl(): string {
        if (this._configuration) {
            return this._configuration.certNetOneBaseUrl;
        }
    }

    public get certNetOneApiSecretKey(): string {
        if (this._configuration) {
            return this._configuration.certNetOneApiSecretKey;
        }
    }

    public get certNetOneTenantId(): string {
        if (this._configuration) {
            return this._configuration.certNetOneTenantId;
        }
    }
}