import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileInfo, FileRestrictions, SelectEvent, SuccessEvent, UploadComponent as KendoUpload, UploadEvent, CancelEvent } from '@progress/kendo-angular-upload';
import { saveAs } from '@progress/kendo-file-saver';
import { AttachmentsService } from '../../attachments/attachments.service';
import { AlertService } from '../alert.service';
import { ApplicationContext } from '../application-context';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})

export class UploadComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() isVisible: boolean = true;
  @Input() showImage: boolean = false;
  @Input() restrictions: FileRestrictions = {};
  @Input() autoUpload: boolean = false;
  @Input() autoDelete: boolean = this.autoUpload;
  @Input() tenantId?: string;
  @Input() hasSizeValidate: boolean = false;
  @Input() max_height: number;
  @Input() max_width: number;
  @Output() onImageLoad: EventEmitter<any> = new EventEmitter();
 
  @ViewChild('upload') kendUploadComponent: KendoUpload;

  public isLoaded = false;
  image: any;
  uploadSaveUrl: string;
  uploadRemoveUrl: string;
  internalName: string;
  isModalVisible: boolean = false;
  retry = true;

  constructor(private applicationContext: ApplicationContext,
    private attachmentSvc: AttachmentsService,
    private alertSvc: AlertService,
    private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (!this.tenantId)
      this.uploadSaveUrl = this.uploadRemoveUrl = `${this.applicationContext.configurationService.apiBaseUrl}/attachment/`;
    else {
      let tenantId = this.tenantId ? this.tenantId : this.attachmentSvc.tenantId;
      this.uploadSaveUrl = this.uploadRemoveUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/attachment/`;
    }


    this.loadImage();
  }

  public loadImage() {
    this.image = null;
    let internalName = this.internalName = this.formGroup.get(this.controlName + '.internalName') ? this.formGroup.get(this.controlName + '.internalName').value : undefined
    this.isLoaded = false;
    if (internalName && this.showImage) {
      this.attachmentSvc.getContentByFileName(internalName, this.tenantId).subscribe(content => {
        if (content) {
          let _image: any = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + content);
          this.image = _image.changingThisBreaksApplicationSecurity;
          this.isLoaded = true;
          this.onImageLoad.emit(this.image);
        }
        else {          
          this.formGroup.controls[this.controlName].patchValue({
            contentType: null,
            effectiveDate: null,
            internalName: null,
            name: null
          });
          this.isLoaded = true;
        }
      },
        error => {          
          this.formGroup.controls[this.controlName].patchValue({
            contentType: null,
            effectiveDate: null,
            internalName: null,
            name: null
          });
          this.isLoaded = true;
        }
      );
    }
    else
      this.isLoaded = true;
  }

  public download() {
    if (this.kendUploadComponent && this.kendUploadComponent.fileList.count > 0) {
      let data: FileInfo = this.kendUploadComponent.fileList.get(this.internalName.split('.')[0])[0];
      var blob = new Blob([data.rawFile], { type: data.rawFile.type });
      var url = window.URL.createObjectURL(blob);
      saveAs(url, data.name);
    }
    else {
      this.attachmentSvc.getAttachmentByFileName(this.formGroup.get(this.controlName + '.internalName').value,
        this.formGroup.get(this.controlName + '.contentType').value, this.formGroup.get(this.controlName + '.name').value, this.tenantId);
    }
  }

  public remove() {
    if (this.autoDelete === true) {
      this.deleteFile(this.internalName);
    }

    if (this.kendUploadComponent && this.kendUploadComponent.fileList.count > 0)
      this.kendUploadComponent.fileList.remove(this.internalName.split('.')[0]);

    this.image = null;
    this.onImageLoad.emit(null);
    this.formGroup.controls[this.controlName].patchValue({
      contentType: null,
      effectiveDate: null,
      internalName: null,
      name: null,
    });

    this.formGroup.markAsDirty();
    this.formGroup.controls[this.controlName].markAsDirty();    
  }

  public validateImage(file: any) {
        
      if (!file.validationErrors) {
        const reader = new FileReader();
        const img = new Image();
        img.src = window.URL.createObjectURL(file.rawFile);

        img.onload = () => {
            var width = img.naturalWidth,
                height = img.naturalHeight;

            window.URL.revokeObjectURL(img.src);

            if(width > this.max_width || height > this.max_height) {
                this.remove();
                this.formGroup.controls[this.controlName].setErrors({ imagesize: true, message: 'The Image size is limited to ' + this.max_width + ' x ' + this.max_height + ' .'});
            }
        };
      }
  }

  public selectEventHandler(e: SelectEvent, data) {
    
    let file = e.files[0];
    this.internalName = file.uid + file.extension;

    if(this.hasSizeValidate){
        this.validateImage(file)
    }

    if (this.restrictions.allowedExtensions && !this.restrictions.allowedExtensions.some(n => n.replace('.', '').toLowerCase() === file.extension.replace('.', '').toLowerCase())) {
      this.formGroup.controls[this.controlName].setErrors({ invalidFileExtension: true })
      return;
    }

    if (this.showImage === true) {
      var myReader: FileReader = new FileReader();
      myReader.onloadend = (e) => {
        this.image = myReader.result;
        this.onImageLoad.emit(this.image);
      }
      myReader.readAsDataURL(file.rawFile);
    }

    this.formGroup.controls[this.controlName].patchValue({
      contentType: file.rawFile.type,
      effectiveDate: new Date(),
      internalName: this.internalName,
      name: file.name,
    })
    this.formGroup.markAsDirty();
    this.formGroup.controls[this.controlName].markAsDirty();

  }

  public uploadFile() {
    if (!this.tenantId)
      this.uploadSaveUrl = this.uploadRemoveUrl = `${this.applicationContext.configurationService.apiBaseUrl}/attachment/`;
    else {
      let tenantId = this.tenantId ? this.tenantId : this.attachmentSvc.tenantId;
      this.uploadSaveUrl = this.uploadRemoveUrl = `${this.applicationContext.configurationService.apiBaseUrl}/tenant/${tenantId}/attachment/`;
    }

    //this.kendUploadComponent.uploadFiles();
    this.kendUploadComponent.fileList.filesToUpload.forEach(n => {
      if (n.length > 0 && n[0].rawFile){
        this.attachmentSvc.uploadFilesByFormData([{file: n[0].rawFile, internalName: n[0].uid.toUpperCase() + n[0].extension.toUpperCase() }], this.tenantId)
                          .subscribe(n => {
                            this.kendUploadComponent.fileList.filesToUpload.splice(0);
                          }, error => {this.alertSvc.error('Error uploading file(s). Details: ' + error)});
      }            
    });
  }

  public deleteFile(internalName: string) {
    if (internalName) {
      if (this.autoUpload)
        this.applicationContext.loadingService.show('main');

      this.attachmentSvc.delete(internalName, this.tenantId)
        .subscribe(n => {
          if (this.autoUpload)
            this.applicationContext.loadingService.hide('main')
        },
          error => {
            if (this.autoUpload)
              this.applicationContext.loadingService.hide('main')
            this.alertSvc.error(error);
          });
    }
  }

  public uploadEventHandler(e: UploadEvent) {
    e.data = {
      internalName: this.internalName
    };
  }

  public successEventHandler(e: SuccessEvent) {
    this.loadImage();
    //this.alertSvc.success(this.applicationContext.i18nService.getTranslation('File uploaded successfully.'));
  }

  public errorEventHandler(e: ErrorEvent) {
    this.alertSvc.error(e.message);
  }

  public removeEventHandler() {
    this.formGroup.controls[this.controlName].updateValueAndValidity();
  }

  public openImageModal() {
    this.isModalVisible = true;
  }

  public closeImageModal() {
    this.isModalVisible = false;
  }

  public cancelEventHandler(e: CancelEvent) {
    if (this.retry){
      this.kendUploadComponent.uploadFiles();
      this.retry = false;
    }    
  }

  
}
