import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, OnDestroy } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription, Subject, of } from "rxjs";
import { I18nService } from "../../i18n";
import { IDropdownList } from "../dropdown-list/dropdown-list.model";
import { ISearchCriteriaItem } from "../search-criteria-item";
import { Service } from "../service";
import { IdNameValueObject } from "../id-name-value-object.model";
import { debounceTime, distinctUntilChanged, mergeMap, delay } from "rxjs/operators";

declare var $: any;

@Component({
    selector: 'ignite-dropdown',
    templateUrl: './ignite-dropdown.component.html',
    styleUrls: ['./ignite-dropdown.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IgniteDropdownComponent),
            multi: true,
        }]
})
export class IgniteDropdownComponent implements ControlValueAccessor, OnInit, OnDestroy {   
    data: IDropdownList[] = [];
    loading: boolean = false
    @Input() selectedItem: IDropdownList
    @Input() service: Service;
    @Input() searchMethod: string = 'search';
    @Input() searchProperty: string = 'name';
    @Input() additionalSearchProperties: string[] = [];
    @Input() convertFunction: any;
    @Input() isComplexObject: boolean = false;
    @Input() defaultItem: IDropdownList = { id: null, name: this.i18nService.getTranslation('Select...') }
    @Input() aditionalfilters: ISearchCriteriaItem[];
    @Input() inactivatedItems: string[] = [];
    @Input() tenantId: string;
    @Input() labelField: string;
    @Input() isSearchForId: boolean = false;
    @Input() isDropup: boolean = false;
    @Input() inputClass: string = '';
    @Input() showGlobalBagde: boolean = false;
    @Input() cacheLastSelection: boolean = false;
    @Input() controlId: string = 'control';
    @Input() tenantIds: string[];
    public lastSelectedItem?: IdNameValueObject;

    @Output() onChangeHandler: EventEmitter<any> = new EventEmitter();
    @Output() onFilterChangeHandler: EventEmitter<any> = new EventEmitter();

    @ViewChild('search') searchElement: ElementRef;

    public searchText: string;
    isDisabled: boolean;
    private request: Subscription;
    public listOverflow = 'hidden';
    public minWidth: string;    

    public keyUp = new Subject<string>();

    private subscription: Subscription;

    @ViewChild('selectButton') button: ElementRef;
    constructor(private i18nService: I18nService) {

        this.subscription = this.keyUp.pipe(            
            debounceTime(800),
            distinctUntilChanged(),           
          ).subscribe(value => {
            this.data = [];

            const searchCriteriaItems: ISearchCriteriaItem[] = [];

            if (this.searchProperty) {
                searchCriteriaItems.push({
                    propertyName: this.searchProperty,
                    condition: "and",
                    operation: "contains",
                    value1: value,
                    value2: null
                });
            }

            if (this.additionalSearchProperties && this.additionalSearchProperties.length > 0) {
                this.additionalSearchProperties.forEach(searchProperty => 
                    searchCriteriaItems.push({
                        propertyName: searchProperty,
                        condition: "or",
                        operation: "contains",
                        value1: value,
                        value2: null
                    })
                );
            }
            
            this.getData(searchCriteriaItems)
        });

     }

    ngOnInit() {
        if (!this.selectedItem || this.selectedItem == null || this.selectedItem.id == null || this.selectedItem.id == "")
            this.selectedItem = this.defaultItem;
        if (this.selectedItem !== null && this.selectedItem.id != null)
            this.data.push({ id: this.selectedItem.id, name: this.selectedItem.name });

        if (this.cacheLastSelection === true){
            this.lastSelectedItem = JSON.parse(sessionStorage.getItem('lastSelectedItem-' + this.controlId));
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private propagateChange = (_: any) => { };

    private propagateBlur = (_: any) => { };

    writeValue(obj: any): void {
        if (obj === '' || obj === null) {
            this.selectedItem = this.defaultItem;
            return;
        }

        if (this.isComplexObject && obj !== null && obj.id !== null && obj.id !== '')
            this.selectedItem = obj;
        else {
            if (obj !== null && obj !== '') {

                if (this.isSearchForId) {
                    this.loading = true;
                    this.service["getById"](obj).subscribe(result => {
                        if (this.convertFunction) 
                            result = this.convertFunction([result])[0];

                        this.data.push({ 
                            id: result.id, 
                            name: result.name, 
                            badge: this.labelField ? result[this.labelField] : null,
                            isGlobal: (result.tenantId === undefined || result.tenantId === '' || result.tenantId === null) 
                        })                        
                        this.selectedItem = this.data.find(n => n.id === obj);
                        this.loading = false;
                    },
                    error => {
                        this.loading = false;
                    }
                    );
                }
                else
                    this.selectedItem = this.data.find(n => n.id === obj);
            }
        }       
    }

    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any) {
        this.propagateBlur = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public onChange(event?) {
        if (event) {
            this.selectedItem = event;
            // update the form
            this.propagateChange(this.isComplexObject ? this.selectedItem : this.selectedItem.id);           
        }
        else {
            this.selectedItem = this.defaultItem;
            // update the form
            this.propagateChange(null);
        }

        this.onChangeHandler.emit(this.isComplexObject ? this.selectedItem : this.selectedItem.id);

        if (event && this.cacheLastSelection === true){
            this.lastSelectedItem = this.selectedItem;
            sessionStorage.setItem('lastSelectedItem-' + this.controlId, JSON.stringify(this.lastSelectedItem));
        }
    }

    onBlur(event) {
        this.propagateBlur(event);
    }

    public filterChange(value) {
        this.keyUp.next(value);

        this.onFilterChangeHandler.emit(value);
    }

    public sortItems(data: IDropdownList[]): IDropdownList[] {
        return data.sort((n1, n2) => {
            if (n1.name.toLowerCase().trim() > n2.name.toLowerCase().trim()) {
                return 1;
            }
            if (n1.name.toLowerCase().trim() < n2.name.toLowerCase().trim()) {
                return -1;
            }
            return 0;
        });
    }

    public scroll() {
        if (!this.loading) {
            let searchCriteriaItems: ISearchCriteriaItem[] = [];

            if (this.searchText && this.searchText != null) {

                if (this.searchProperty) {
                    searchCriteriaItems.push({
                        propertyName: this.searchProperty,
                        condition: "and",
                        operation: "contains",
                        value1: this.searchText,
                        value2: null
                    });
                }
    
                if (this.additionalSearchProperties && this.additionalSearchProperties.length > 0) {
                    this.additionalSearchProperties.forEach(searchProperty => 
                        searchCriteriaItems.push({
                            propertyName: searchProperty,
                            condition: "or",
                            operation: "contains",
                            value1: this.searchText,
                            value2: null
                        })
                    );
                }               
            }

            this.getData(searchCriteriaItems);
        }
    }

    onShow() {
        this.minWidth = this.button.nativeElement.offsetWidth + 'px';
        this.listOverflow = 'hidden';
        this.searchText = '';
        this.data = [];

        // if (this.selectedItem && this.selectedItem !== null && this.selectedItem.id != null)
        //     this.data.push({ id: this.selectedItem.id, name: this.selectedItem.name, badge: this.selectedItem.badge });

        this.getData([]);

        setTimeout(() => {
            this.searchElement.nativeElement.focus();
        }, 0);
    }

    onHide() {
        this.data = [];
    }

    private getData(searchCriteriaItems: ISearchCriteriaItem[]) {
        // if (this.selectedItem != null && this.selectedItem.id != null) {
        //     searchCriteriaItems.push({
        //         propertyName: this.searchProperty,
        //         condition: "and",
        //         operation: "!=",
        //         value1: this.selectedItem.name,
        //         value2: null
        //     });
        // }

        if (this.aditionalfilters) {
            this.aditionalfilters.forEach(n => searchCriteriaItems.push(n));
        }

        let pageSize = 15;
        let pageNumber = Math.ceil((this.data.length - 1) / pageSize) + 1;

        this.loading = true;

        if (this.request && !this.request.closed)
            this.request.unsubscribe();

        this.request = this.service[this.searchMethod]({
            pageNumber: pageNumber,
            pageSize: pageSize,
            searchCriteriaItems: searchCriteriaItems || []
        }, this.tenantIds ? this.tenantIds : this.tenantId).subscribe(result => {
            if (this.convertFunction) {
                this.convertFunction(result).forEach(n => {
                    if (!this.data.some(x => x.id ===  n.id)){
                        this.data.push({ id: n.id, 
                            name: n.name, 
                            badge: this.labelField ? n[this.labelField] : null, 
                            isGlobal: (n.tenantId === undefined || n.tenantId === '' || n.tenantId === null) 
                           });
                    }
                    
                });
            }
            else {
                result.forEach(n => {
                    if (!this.data.some(x => x.id ===  n.id)){
                        this.data.push({ id: n.id, 
                            name: n.name, 
                            badge: this.labelField ? n[this.labelField] : null, 
                            isGlobal: (n.tenantId === undefined || n.tenantId === '' || n.tenantId === null) 
                        });
                    }
                });
            }
            this.loading = false;
            this.listOverflow = 'auto';
        },
            error => {
                this.loading = false;
                this.listOverflow = 'auto';
                //this.alertService.error(error);
            }
        );
    }

    public isItemDisabled(id: string): boolean {
        return this.inactivatedItems.indexOf(id) >= 0;
    }

    private getControlWith() {
        return $('#button-basic').width();
    }
}