import { RouterModule, Routes } from "@angular/router";
import { ScopeGuardService } from "app/+auth/scope-guard.service";
import { ActiveSessionsListComponent } from "./active-sessions/active-sessions-list.component";
import { SynchronizationComponent } from "./synchronization/synchronization.component";

export const routes: Routes = [
    {
        path: 'active-sessions',
        component: ActiveSessionsListComponent,
        canActivate: [ScopeGuardService],
        data: { pageTitle: 'Active Sessions', expectedScopes: ["admin:infochip"] }
    },
    {
        path: 'synchronization',
        component: SynchronizationComponent,
        canActivate: [ScopeGuardService],
        data: { pageTitle: 'Synchronization', expectedScopes: ["admin:infochip"] }
    }    
];

export const Routing = RouterModule.forChild(routes);