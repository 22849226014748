import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadTimeService } from "app/shared/ignite/load-time/load-time.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class AuthenticationTokenInterceptor implements HttpInterceptor {
    constructor(private loadTimeService: LoadTimeService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const idToken = localStorage.getItem("access_token");
        if (idToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${idToken}`                    
                }
            });
        }

        let startDate = new Date();

        return next.handle(request).pipe(
            tap((httpEvent: HttpEvent<any>) => {
                if (httpEvent instanceof HttpResponse) {
                    var totalExecutionTime = Math.abs(new Date().getTime() - startDate.getTime());

                    this.loadTimeService.Add(totalExecutionTime);
                }
            }));
    }
}